import { createStore } from '@azondo/utils'
import { AUTH_KEY_NAME, authReducer } from '@azondo/api'

import appConfig from 'config/app'

import { dataGridReducers } from './dataGridReducers'

const { store, persistor } = createStore(
  {
    [AUTH_KEY_NAME]: authReducer,
    ...dataGridReducers,
  },
  {
    key: 'dashboardRoot',
    devTools: appConfig.appEnv === 'development',
    version: 1,
    blacklist: [AUTH_KEY_NAME],
  },
)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export { store, persistor }
