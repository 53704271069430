import styled from 'styled-components';
import { flexbox, space } from 'styled-system';

const CenterDiv = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;
const FlexDiv = styled.div`
  display: flex;
  ${flexbox};
  ${space};
`;
const AbsoluteCenter = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export { AbsoluteCenter, CenterDiv, FlexDiv };
