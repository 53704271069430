import CircularProgress from '@mui/material/CircularProgress'
import { memo } from 'react'
import { Route } from 'react-router-dom'

const LoadingView = (): JSX.Element => (
  <Route>
    <CircularProgress />
  </Route>
)

export default memo(LoadingView)
