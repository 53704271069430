import { alpha } from '@mui/material/styles';

const palette = {
  mode: "light",
  background: {
    paper: "#FFFFFF",
    default: "#314055"
  },
  common: {
    black: "#000",
    white: "#fff"
  },
  primary: {
    main: "#4EAC60",
    dark: "#3C6758",
    contrastText: "#fff"
  },
  secondary: {
    main: "#1F2128",
    dark: "#191b21",
    contrastText: "#fff"
  },
  info: {
    main: "#5AB9FE"
  },
  success: {
    main: "#42c25c"
  },
  warning: {
    light: "#FFE58F",
    main: "#FAAD14"
  },
  error: {
    main: "#EE786C",
    dark: "#bc4538",
    contrastText: "#fff"
  },
  bankIdViolet: {
    main: "#39134C",
    dark: "#2a0140",
    contrastText: "#fff"
  },
  orange: {
    main: "#F7931A",
    contrastText: "#fff"
  },
  text: {
    primary: "#222222",
    secondary: "#757785"
  },
  textInverted: "#FFFFFF",
  textBlueish: "#324A57",
  greyDefault: "#9FA2B4",
  border: "#EFEFF3",
  borderLight: alpha("#9FA2B4", 0.1),
  placeholder: "#F7F8FC",
  bgLight: "#F7F8FC",
  grey: {
    900: "#262626",
    800: "#595959",
    700: "#9FA2B4",
    600: "#BFBFBF",
    300: "#DFE0EB",
    200: "#EAEFF4",
    100: "#F7F8FC"
  }
};

export { palette as default };
