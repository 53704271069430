import { ApiErrorBase } from '../core/ApiErrorBase.js';
import { ValidationException } from './ValidationException.js';
import { ApiException } from './ApiException.js';

async function checkBLobData(error) {
  var _a, _b;
  if (((_a = error.request) == null ? void 0 : _a.responseType) === "blob" && error.response && error.response.data instanceof Blob && error.response.data.type && error.response.data.type.toLowerCase().indexOf("json") !== -1) {
    const responseObj = await error.response.data.text();
    return JSON.parse(responseObj);
  }
  return (_b = error.response) == null ? void 0 : _b.data;
}
async function extractApiError(axiosError) {
  return checkBLobData(axiosError).then((data) => {
    if (axiosError.response) {
      const { status } = axiosError.response;
      if (data.detail) {
        return new ValidationException(data, axiosError.response);
      }
      if (data.errors) {
        return new ApiException(data, axiosError.response);
      }
      return new ApiErrorBase({
        message: axiosError.message,
        statusCode: status,
        details: data
      });
    }
    if (axiosError.message) {
      return new ApiErrorBase({
        message: axiosError.message,
        statusCode: axiosError.code ? +axiosError.code : void 0
      });
    }
    return new ApiErrorBase({
      message: "Unhandled error happened"
    });
  });
}

export { extractApiError };
