import { useAuth0 } from '@auth0/auth0-react';

function useMyProfile() {
  const { isLoading, user, error } = useAuth0();
  const tempUser = {
    id: user == null ? void 0 : user.sub,
    access_policies: [{ name: "SA" }, { name: "AB2C" }, { name: "AB2B" }],
    email: (user == null ? void 0 : user.email) || "",
    email_confirmed: true,
    nationality: "",
    user_status: (user == null ? void 0 : user.email_verified) ? "verified" : "new",
    is_banned: false,
    is_active: true,
    first_name: (user == null ? void 0 : user.name) || "",
    last_name: (user == null ? void 0 : user.family_name) || "",
    date_of_birth: ""
  };
  return { isLoading, error, data: tempUser };
}

export { useMyProfile };
