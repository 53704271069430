const kycBanksProvidersArr = ["bank_id", "bank_id_fi", "bank_id_no"];
const kycProvidersArr = [...kycBanksProvidersArr, "manual", "sumsub"];
const kycSteps = {
  bank_id: ["bank_id", "residence", "personal", "done"],
  bank_id_fi: ["bank_id", "residence", "personal", "done"],
  bank_id_no: ["bank_id", "residence", "personal", "done"],
  manual: ["residence", "personal", "documents", "done"],
  sumsub: ["residence", "personal", "documents", "done"]
};

export { kycBanksProvidersArr, kycProvidersArr, kycSteps };
