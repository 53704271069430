var UserRole = /* @__PURE__ */ ((UserRole2) => {
  UserRole2["user"] = "User";
  UserRole2["admin"] = "Admin";
  UserRole2["superAdmin"] = "Super Admin";
  return UserRole2;
})(UserRole || {});
const userRoleNames = {
  ["User" /* user */]: "User",
  ["Admin" /* admin */]: "Admin",
  ["Super Admin" /* superAdmin */]: "Super Admin"
};

export { UserRole, userRoleNames };
