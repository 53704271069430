import styled from 'styled-components';
import TextField from '@mui/material/TextField';
import { margin } from 'styled-system';

const TextFieldStyled = styled(TextField)`
  width: -webkit-fill-available;
  ${margin};
`;

export { TextFieldStyled };
