import { ThemeProvider as ThemeProvider$1 } from 'styled-components';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import GlobalCss from '../../styles/globalStyles/GlobalCss.js';
import ResetCss from '../../styles/globalStyles/ResetCss.js';
import GlobalCss$1 from '../../styles/globalStyles/GlobalPayCss.js';
import theme from '../../styles/theme/index.js';
import { jsx, jsxs } from 'react/jsx-runtime';

function StylesProvider({
  children,
  platform
}) {
  return /* @__PURE__ */ jsx(StyledEngineProvider, {
    injectFirst: true,
    children: /* @__PURE__ */ jsx(ThemeProvider, {
      theme,
      children: /* @__PURE__ */ jsxs(ThemeProvider$1, {
        theme,
        children: [/* @__PURE__ */ jsx(GlobalCss, {}), /* @__PURE__ */ jsx(ResetCss, {}), /* @__PURE__ */ jsx(CssBaseline, {}), platform === "pay" && /* @__PURE__ */ jsx(GlobalCss$1, {}), children]
      })
    })
  });
}

export { StylesProvider as default };
