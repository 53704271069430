import { useCallback, useEffect, createContext } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useContextWithCheck } from '@azondo/utils';
import { useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { tokenToString } from '../../utils/tokenUtils.js';
import '../../domain/currency.js';
import '../../domain/user/userKyc.js';
import { setTokenPair, logoutUser } from '../../reduxSlices/auth/auth.actions.js';
import '../../reduxSlices/auth/auth.reducer.js';
import { useAzondoApiContext } from '../AzondoApiProvider/AzondoApiProvider.js';
import { jsx } from 'react/jsx-runtime';

var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
const AuthContext = /* @__PURE__ */ createContext(void 0);
const AuthProvider = ({
  children,
  onLogout,
  store
}) => {
  const {
    isLoading,
    getAccessTokenSilently,
    isAuthenticated,
    loginWithRedirect,
    logout: logoutAuth0
  } = useAuth0();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const {
    apiInstance,
    apiStagingInstance
  } = useAzondoApiContext();
  const login = useCallback((newTokens) => {
    dispatch(setTokenPair(newTokens));
  }, [dispatch]);
  const loginAuth0 = () => loginWithRedirect();
  const logout = useCallback(() => {
    logoutAuth0({
      returnTo: window.location.origin
    });
    onLogout == null ? void 0 : onLogout();
    dispatch(logoutUser());
    queryClient.clear();
  }, [dispatch, onLogout, queryClient, logoutAuth0]);
  useEffect(() => {
    apiInstance.axiosClient.interceptors.request.use(async (request) => {
      var _a;
      const accessToken = await getAccessTokenSilently();
      const token = {
        token: accessToken,
        token_type: "Bearer",
        expire: "86400"
      };
      if (accessToken) {
        request.headers = __spreadProps(__spreadValues({}, request.headers), {
          Authorization: request.url === "/auth/refresh" ? ((_a = request.headers) == null ? void 0 : _a.Authorization) || tokenToString(token) : tokenToString(token)
        });
      }
      return request;
    });
    apiStagingInstance.axiosClient.interceptors.request.use(async (request) => {
      var _a;
      const accessToken = await getAccessTokenSilently();
      const token = {
        token: accessToken,
        token_type: "Bearer",
        expire: "86400"
      };
      if (accessToken) {
        request.headers = __spreadProps(__spreadValues({}, request.headers), {
          Authorization: request.url === "/auth/refresh" ? ((_a = request.headers) == null ? void 0 : _a.Authorization) || tokenToString(token) : tokenToString(token)
        });
      }
      return request;
    });
  }, [apiInstance, apiStagingInstance, store, getAccessTokenSilently]);
  useEffect(() => {
    createAuthRefreshInterceptor(apiInstance.axiosClient, async (failedRequest) => {
      await getAccessTokenSilently().then((access_token) => {
        const token = {
          token: access_token,
          token_type: "Bearer",
          expire: "86400"
        };
        if (failedRequest) {
          failedRequest.response.config.headers["Authorization"] = tokenToString(token);
        }
      }).catch((error) => console.error("%o", error));
    }, {
      statusCodes: [401]
    });
  }, [apiInstance, store, getAccessTokenSilently]);
  return /* @__PURE__ */ jsx(AuthContext.Provider, {
    value: {
      isReady: isAuthenticated,
      isLoading,
      login,
      loginAuth0,
      logout
    },
    children
  });
};
const useAuth = () => useContextWithCheck(AuthContext);

export { AuthProvider, useAuth };
