export function calcChangePercent(current: number | undefined, previous: number | undefined) {
  if (previous == null || current == null || isNaN(previous) || isNaN(current)) {
    return undefined
  }

  if (previous === 0) {
    if (current === 0) {
      return 0
    }
    return undefined
  }
  return (current / previous - 1) * 100
}

export const calcRatioPercent = (count: number | undefined, total: number | undefined) => {
  if (count == null) return undefined
  if (total == null) return undefined
  if (total === 0) {
    if (count === 0) {
      return 0
    }
    return 100
  }

  const res = (count / Math.trunc(total)) * 100
  if (Number.isNaN(res)) return undefined
  return res
}

export const arraysEqual = (a: any[], b: any[]) => {
  if (a === b) return true
  if (a == null || b == null) return false
  if (a.length !== b.length) return false

  for (let i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false
  }
  return true
}
