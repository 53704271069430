var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
const cacheKeys = {
  b2bTransactions: (params) => ["transactions", params],
  transactionInfo: (txId) => ["transactionInfo", { txId }],
  quotePrice: (currency) => ["quotePrice", { currency }],
  webhooks: (params) => ["webhooks", params],
  webhookInfo: (webhookId) => ["webhookInfo", { webhookId }],
  users: (params) => ["users", params],
  userInfo: (userId) => ["user", { userId }],
  userTransactions: (userId, params) => [
    "userTransactions",
    __spreadValues({ userId }, params)
  ],
  userWebhooks: (userId, params) => ["userWebhooks", __spreadValues({ userId }, params)],
  apiKeys: (params) => ["apiSettings", params],
  clientSecretInfo: (id) => ["clientSecretInfo", { id }],
  mfaSecret: "mfaSecret",
  userDashboard: (userId, params) => ["userDashboard", __spreadValues({ userId }, params)],
  adminDashboard: (params) => ["adminDashboard", params],
  dashboardChart: (userId, params) => [
    "dashboardChart",
    __spreadValues({ userId }, params)
  ],
  dashboardFeeHistory: (userId, params) => [
    "dashboardFeeHistory",
    __spreadValues({ userId }, params)
  ],
  userFeeLevels: (userId) => ["userFeeLevels", { userId }],
  feeLevel: (id) => ["feeLevel", { id }],
  userCurrentFeeLevel: (userId) => ["userCurrentFeeLevel", { userId }]
};

export { cacheKeys as default };
