function capitalize(str) {
  return str ? str.charAt(0).toUpperCase() + str.slice(1) : str;
}
function formatInternalString(str, fallback = "") {
  if (!str)
    return fallback;
  return capitalize(str.split("_").join(" "));
}

export { capitalize, formatInternalString };
