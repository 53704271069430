import { useContext } from 'react';

function useContextWithCheck(ClassContext) {
  const context = useContext(ClassContext);
  if (!context) {
    throw new Error("useContext must be used within the <ContextProvider>");
  }
  return context;
}

export { useContextWithCheck };
