import { memo, useEffect } from 'react'
import { Route as ReactRoute, useRouteMatch } from 'react-router-dom'

import { useAccessPoliciesContext } from 'contexts/AccessPolicies'
import { arraysEqual } from 'utils/dashboardUtils'

import LoadingView from '../ProtectedRoute/components/LoadingView'

import { PolicyRouteProps } from '.'

const PolicyRoute = ({ policies, ...props }: PolicyRouteProps) => {
  const { setPoliciesIds, policies: saved } = useAccessPoliciesContext()
  const selected = !!useRouteMatch({ exact: true, path: props.path ?? '' })

  useEffect(() => {
    if (policies.length > 0 && selected) {
      setPoliciesIds(policies, selected && arraysEqual(saved, policies))
    }
  }, [setPoliciesIds, policies, saved, selected])

  if (policies.length > 0 && selected && !arraysEqual(saved, policies)) {
    return <LoadingView />
  }

  return <ReactRoute {...props} />
}

export default memo(PolicyRoute)
