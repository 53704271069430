import { useEffect } from 'react';
import { formatDate } from '../formatters/dateFormatters.js';
import 'i18next';

const useAppDocMetaData = ({ version, buildTime }) => {
  useEffect(() => {
    document.head.prepend(document.createComment(`App version: ${version}`));
    document.head.prepend(document.createComment(`Build time: ${formatDate(buildTime)}`));
  }, [buildTime, version]);
};

export { useAppDocMetaData };
