import { useState, useCallback } from 'react';

function useModalState({ initialOpen = false } = {}) {
  const [open, setOpen] = useState(initialOpen);
  const onOpen = useCallback(() => {
    setOpen(true);
  }, []);
  const onClose = useCallback(() => {
    setOpen(false);
  }, []);
  const onToggle = useCallback((isOpen) => {
    setOpen((prevState) => isOpen != null ? isOpen : !prevState);
  }, []);
  return { onOpen, onClose, open, onToggle };
}

export { useModalState };
