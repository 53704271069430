import { useContext, createContext } from 'react';
import { jsx } from 'react/jsx-runtime';

var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
const ServiceApiContext = /* @__PURE__ */ createContext(void 0);
const ServiceApiProvider = ({
  children,
  apiProps
}) => {
  return /* @__PURE__ */ jsx(ServiceApiContext.Provider, {
    value: __spreadValues({}, apiProps),
    children
  });
};
const useServiceApiProps = () => {
  const context = useContext(ServiceApiContext);
  return context ? context : void 0;
};

export { ServiceApiProvider, useServiceApiProps };
