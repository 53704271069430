import React, { memo } from 'react'
import { withAuthenticationRequired } from '@auth0/auth0-react'
import { Redirect, Route as ReactRoute } from 'react-router-dom'
import { useAuth, useMyProfile, useMyRole } from '@azondo/api'
import { useSnackbar } from 'notistack'

import { adminRoutes } from 'routes'
import { useCheckAccess } from 'hooks/helpers/useCheckAccess'
import InProgress from 'components/InProgress'

import PolicyRoute from '../PolicyRoute'

import ProfileRequired from './components/ProfileRequired'
import LoadingView from './components/LoadingView'
import ErrorView from './components/ErrorView'

import { ProtectedRouteProps } from '.'

const ProtectedRoute = ({ nonAuthorized, policies, inProgress, ...rest }: ProtectedRouteProps) => {
  const { enqueueSnackbar } = useSnackbar()
  const { isReady, isLoading, logout } = useAuth()
  const myProfile = useMyProfile()
  const myRole = useMyRole()
  const { isBlocked } = useCheckAccess(policies)

  if (myProfile.isLoading || myRole.isLoading || isLoading || (isReady && !myRole.data)) {
    return <LoadingView />
  }

  if (myRole.data === 'user') {
    logout()
    enqueueSnackbar('Access denied', { variant: 'error' })
    return <LoadingView />
    //      return <RedirectWithMsg to={appRoutes.nonProtectedRedirect.path} errorMsg='Access denied' />
  }

  if (nonAuthorized && isReady) {
    return <Redirect to={adminRoutes.dashboard.path} />
  }

  if (inProgress) {
    return <InProgress />
  }

  const { authorized, verified, ...routeProps } = rest

  if (authorized || verified) {
    return <ProfileRequired {...rest} />
  }

  if (isBlocked) {
    return (
      <ErrorView title='You do not have permission to access this resource. Please contact the administrator.' />
    )
  }

  return nonAuthorized ? (
    <ReactRoute {...routeProps} />
  ) : (
    <PolicyRoute
      component={withAuthenticationRequired(routeProps?.component || LoadingView, {
        onRedirecting: () => <div>redirecting</div>,
      })}
      {...routeProps}
      policies={policies || []}
    />
  )
}

export default memo(ProtectedRoute)
