const notificationStatusesArr = [
  "created",
  "executing",
  "executed",
  "expired",
  "failed",
  "canceled"
];

export { notificationStatusesArr };
