import { useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useFormInputRegister } from './useFormInputRegister.js';

var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
function useYupForm(schema, formProps) {
  const methods = useForm(__spreadProps(__spreadValues({
    mode: "all"
  }, formProps), {
    resolver: yupResolver(schema)
  }));
  const setApiError = useCallback((apiError) => {
    if (apiError == null) {
      methods.clearErrors("global");
    } else {
      if (typeof apiError.details === "object") {
        Object.entries(apiError.details).forEach(([key, err]) => {
          methods.setError(key, {
            type: "apiError",
            message: err.toString()
          });
        });
      }
      methods.setError("global", {
        type: "apiError",
        message: apiError.toString()
      });
    }
  }, [methods]);
  const register = useFormInputRegister(methods, schema);
  return useMemo(() => __spreadProps(__spreadValues({}, methods), {
    setApiError,
    register
  }), [methods, register, setApiError]);
}

export { useYupForm };
