import { memo, ReactNode } from 'react'
import InfoMessage from '@azondo/ui/dist/components/InfoMessage'
import { Route } from 'react-router-dom'

import useTranslation from 'hooks/useTranslation'

const ErrorView = ({ title, text }: { title?: string; text?: ReactNode }): JSX.Element => {
  const { t } = useTranslation()
  return (
    <Route>
      <div>
        <InfoMessage title={title ?? t('auth.notAllowed')} text={text} nature='error' />
      </div>
    </Route>
  )
}

export default memo(ErrorView)
