import { createGlobalStyle } from 'styled-components';

const GlobalCss = createGlobalStyle`
  body {
    min-height: 100vh;
    min-height: -webkit-fill-available;
    display: flex;
    background: ${({ theme }) => theme.palette.background.paper};
    color: ${({ theme }) => theme.palette.textInverted};
  }
`;

export { GlobalCss as default };
