import { createReducer } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import { setTokenPair, clearTokens, setAccessToken, AUTH_KEY_NAME } from './auth.actions.js';

const initialState = {
  accessToken: void 0,
  refreshToken: void 0
};
const reducer = createReducer(initialState, (builder) => {
  builder.addCase(setTokenPair, (state, { payload }) => {
    state.accessToken = payload.access_token;
    state.refreshToken = payload.refresh_token;
  }).addCase(clearTokens, (state) => {
    state.accessToken = void 0;
    state.refreshToken = void 0;
  }).addCase(setAccessToken, (state, { payload }) => {
    state.accessToken = payload;
  });
});
const authPersistConfig = {
  key: AUTH_KEY_NAME,
  storage,
  blacklist: ["accessToken"]
};
const authReducer = persistReducer(authPersistConfig, reducer);

export { authReducer };
