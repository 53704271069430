import styled from 'styled-components';
import Modal from '@mui/material/Modal';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import IconButton from '@mui/material/IconButton';

var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
const animationDuration = 250;
const ModalStyled = styled(Modal)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  outline: "none",
  [theme.breakpoints.up("tabletS")]: {
    padding: theme.spacing(1.5)
  }
}));
const ModalContainer = styled.div(({ theme, maxWidth }) => ({
  display: "flex",
  flexDirection: "column",
  background: theme.palette.common.white,
  color: theme.palette.text.primary,
  width: "100%",
  outline: "none",
  minHeight: 150,
  maxWidth: "100%",
  zIndex: 1,
  [theme.breakpoints.up("tabletS")]: {
    borderRadius: theme.radii.default,
    padding: theme.spacing(0, 2.5),
    maxWidth: maxWidth != null ? maxWidth : 500
  },
  [theme.breakpoints.down("tabletS")]: {
    flex: 1,
    padding: theme.spacing(0, 1.5)
  }
}));
const ModalHead = styled.div(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  minHeight: 70,
  borderBottom: `1px solid ${theme.palette.grey[200]}`
}));
const ModalTitle = styled.div(({ theme }) => __spreadProps(__spreadValues({}, theme.typography.h4), {
  marginRight: "0.5em"
}));
const CloseButton = styled(IconButton).attrs({ edge: "end" })({});
const CloseIcon = styled(CloseRoundedIcon)({
  color: "inherit"
});
const ModalBody = styled.div(({ theme }) => ({
  flex: 1,
  overflowY: "auto",
  fontSize: theme.fontSizes.default,
  padding: theme.spacing(1.5, 0),
  [theme.breakpoints.down("tabletS")]: {
    fontSize: theme.fontSizes.small
  }
}));

export { CloseButton, CloseIcon, ModalBody, ModalContainer, ModalHead, ModalStyled, ModalTitle, animationDuration };
