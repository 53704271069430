import { AdminApi } from '../../azondoAPIs/AdminApi.js';
import { MerchantApi } from '../../azondoAPIs/MerchantApi.js';
import 'auto-bind';
import '@azondo/utils';
import '../../domain/currency.js';
import '../../domain/user/userKyc.js';
import 'react';
import '@auth0/auth0-react';
import 'react-query';
import 'react-redux';
import 'axios-auth-refresh';
import '../../reduxSlices/auth/auth.actions.js';
import '../../reduxSlices/auth/auth.reducer.js';
import { useAzondoApi } from '../../providers/AzondoApiProvider/AzondoApiProvider.js';
import 'react/jsx-runtime';
import { useMyRole } from '../queries/useMyRole.js';

function useAdminOrMerchantApi() {
  const { data: role } = useMyRole();
  const adminApi = useAzondoApi(AdminApi);
  const merchantApi = useAzondoApi(MerchantApi);
  return role === "admin" ? adminApi : merchantApi;
}

export { useAdminOrMerchantApi };
