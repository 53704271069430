import { Route, Switch } from 'react-router-dom'
import { lazyLoad } from '@azondo/ui/dist/components/LazyLoad'
import { useAppDocMetaData } from '@azondo/utils'

import { authRoutes, appRoutes } from 'routes'
import ProtectedRoute from 'routes/components/ProtectedRoute'
import { buildTime, version } from 'utils/appMeta'

const AppSwitch = lazyLoad(() => import('views/AppSwitch'))

const App = () => {
  useAppDocMetaData({ version, buildTime })

  return (
    <Switch>
      <Route {...appRoutes.nonProtectedRedirect} />
      <ProtectedRoute nonAuthorized {...authRoutes} />
      <ProtectedRoute authorized component={AppSwitch} />
    </Switch>
  )
}

export default App
