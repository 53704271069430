var B2CTransactionType = /* @__PURE__ */ ((B2CTransactionType2) => {
  B2CTransactionType2[B2CTransactionType2["purchase"] = 1] = "purchase";
  B2CTransactionType2[B2CTransactionType2["sale"] = 2] = "sale";
  B2CTransactionType2[B2CTransactionType2["transfer"] = 3] = "transfer";
  B2CTransactionType2[B2CTransactionType2["withdraw"] = 4] = "withdraw";
  B2CTransactionType2[B2CTransactionType2["deposit"] = 5] = "deposit";
  return B2CTransactionType2;
})(B2CTransactionType || {});
const b2cTypesNaming = {
  [1 /* purchase */]: "Purchase",
  [2 /* sale */]: "Sale",
  [3 /* transfer */]: "Transfer",
  [4 /* withdraw */]: "Withdrawal",
  [5 /* deposit */]: "Deposit"
};

export { B2CTransactionType, b2cTypesNaming };
