import { memo, Suspense, lazy } from 'react';
import ProgressBar from 'react-topbar-progress-indicator';
import palette from '../../styles/theme/palette.js';
import { jsx } from 'react/jsx-runtime';

var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
ProgressBar.config({
  barColors: {
    0: palette.primary.main,
    1: palette.secondary.main
  },
  shadowBlur: 2
});
function lazyLoad(importFunc, {
  fallback = null
} = {}) {
  const LazyComponent = /* @__PURE__ */ lazy(() => importFunc().catch(() => {
    console.error("LazyLoading failed; Reloading the app to fetch the newest index.html version");
    window.location.reload();
    return {
      default: ProgressBar
    };
  }));
  return /* @__PURE__ */ memo((props) => /* @__PURE__ */ jsx(Suspense, {
    fallback: fallback || /* @__PURE__ */ jsx(ProgressBar, {}),
    children: /* @__PURE__ */ jsx(LazyComponent, __spreadValues({}, props))
  }));
}

export { lazyLoad as default };
