const findItemIndex = (items, item) => {
  const byId = items.findIndex((x) => x.id === item.id);
  if (byId >= 0) {
    return byId;
  }
  const byField = items.findIndex((x) => x.field === item.field);
  return byField >= 0 ? byField : void 0;
};
const findFilterIndex = (state) => (item) => {
  findItemIndex(state.filterItems, item);
};

export { findFilterIndex, findItemIndex };
