class ApiErrorBase extends Error {
  constructor({ message, type, statusCode, details }) {
    super(message);
    this.name = "ApiError";
    this.type = type;
    this.message = message;
    this.statusCode = statusCode;
    this.details = details;
  }
  toString() {
    var _a;
    return ((_a = this.message) == null ? void 0 : _a.toString()) || "Unhandled API error";
  }
}

export { ApiErrorBase };
