import pickBy from 'lodash/pickBy';

function saveFile(file, filename) {
  const url = window.URL.createObjectURL(new Blob([file]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
}
function hasOwnPropertyCall(obj, key) {
  if (!obj)
    return false;
  return Object.prototype.hasOwnProperty.call(obj, key);
}
function getByKey(obj, key, defaultValue = null) {
  if (key == null || !hasOwnPropertyCall(obj, key))
    return defaultValue;
  return obj[key];
}
function elementPosition(elem) {
  if (!elem)
    return null;
  const rect = elem.getBoundingClientRect();
  const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
}
function elementPositionById(id) {
  const elem = document.getElementById(id);
  if (!elem)
    return null;
  return elementPosition(elem);
}
function getAbsoluteUrl(path) {
  return `${window.location.origin}${path}`;
}
function autoImplement(defaults) {
  return class {
    constructor() {
      Object.assign(this, defaults || {});
    }
  };
}
function openPopupWindow({
  url = "about:blank",
  windowParent,
  target,
  width,
  height
}) {
  const win = windowParent != null ? windowParent : window;
  let x = 0;
  let y = 0;
  if (win.top) {
    y = win.top.outerHeight / 2 + win.top.screenY - height / 2;
    x = win.top.outerWidth / 2 + win.top.screenX - width / 2;
  }
  return win.open(url, target, `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${width}, height=${height}, top=${y}, left=${x}`);
}
const removeUndefinedProps = (originalObject) => pickBy(originalObject, (v) => v !== void 0);
const joinStrings = (arr, separator = " ") => arr.filter((str) => str && (str == null ? void 0 : str.toString().length) > 0).join(separator);

export { autoImplement, elementPosition, elementPositionById, getAbsoluteUrl, getByKey, hasOwnPropertyCall, joinStrings, openPopupWindow, removeUndefinedProps, saveFile };
