import autoBind from 'auto-bind';
import { ApiBase } from './ApiBase.js';
import { extractApiError } from '../errors/extractApiError.js';

class AzondoApiBase extends ApiBase {
  constructor({ baseURL }) {
    super({
      baseURL
    });
    autoBind(this);
  }
  async request(options) {
    return super.request(options).catch(async (err) => {
      throw await extractApiError(err);
    });
  }
}

export { AzondoApiBase };
