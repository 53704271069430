import { useQuery } from 'react-query';
import useUpdateQuery from '../helpers/useUpdateQuery.js';
import useService from '../helpers/useService.js';
import cacheKeys from '../../cacheKeys.js';
import UsersService from '../../services/UsersService.js';

var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
function useUser(id, options) {
  const api = useService(UsersService);
  const cacheKey = cacheKeys.userInfo(id);
  const query = useQuery(cacheKey, () => id ? api.getUser(id) : void 0, __spreadValues({
    enabled: !!id
  }, options));
  const updateUser = useUpdateQuery(cacheKey);
  return __spreadValues({
    updateUser
  }, query);
}

export { useUser as default };
