import autoBind from 'auto-bind';
import ApiBase from '../core/ApiBase.js';

var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
const csvExportOptions = {
  headers: {
    Accept: "text/csv",
    "content-type": "text/json"
  },
  responseType: "text"
};
class TransactionsService {
  constructor(props) {
    this.apiBase = new ApiBase(props);
    autoBind(this);
  }
  getAllTransactions(params) {
    return this.apiBase.get("/transactions", __spreadValues({}, params));
  }
  getAllTransactionsCsv(params) {
    return this.apiBase.get("/transactions", __spreadValues({
      format: "csv"
    }, params), csvExportOptions);
  }
  async getTransaction(tx_id) {
    return this.apiBase.get(`/transactions/${tx_id}`).then((res) => res.transaction);
  }
  async getTransactionPdf(tx_id) {
    return this.apiBase.get(`/transactions/${tx_id}`, { format: "pdf" }, {
      headers: {
        Accept: "application/pdf",
        "content-type": "text/json"
      },
      responseType: "arraybuffer"
    });
  }
  getUserTransactions(user_id, params) {
    return this.apiBase.get(`/users/${user_id}/transactions`, params);
  }
  getUserTransactionsCsv(user_id, params) {
    return this.apiBase.get(`/users/${user_id}/transactions`, __spreadValues({
      format: "csv"
    }, params), csvExportOptions);
  }
  async withdraw() {
    return null;
  }
}

export { TransactionsService as default };
