import { useSnackbar } from 'notistack'
import { useEffect } from 'react'
import { Redirect, Route } from 'react-router-dom'

import useTranslation from 'hooks/useTranslation'

const RedirectWithMsg = ({ errorMsg, to }: { errorMsg?: string; to: string }): JSX.Element => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    if (errorMsg) enqueueSnackbar(errorMsg, { variant: 'warning' })
  }, [enqueueSnackbar, errorMsg, t])

  return (
    <Route>
      <Redirect to={to} />
    </Route>
  )
}

export default RedirectWithMsg
