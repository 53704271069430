import { Container, IconWrapper, Content, Title, SmallText } from './InfoMessage.styles.js';
import useInfoMessageStyle from './useInfoMessageStyle.js';
import { jsxs, jsx } from 'react/jsx-runtime';

var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
function InfoMessage(_a) {
  var _b = _a, {
    title,
    text,
    children,
    nature = "info"
  } = _b, rest = __objRest(_b, [
    "title",
    "text",
    "children",
    "nature"
  ]);
  const {
    color,
    Icon
  } = useInfoMessageStyle(nature);
  const inner = children || text;
  return /* @__PURE__ */ jsxs(Container, __spreadProps(__spreadValues({
    $color: color
  }, rest), {
    children: [/* @__PURE__ */ jsx(IconWrapper, {
      $color: color,
      children: /* @__PURE__ */ jsx(Icon, {
        color: "inherit"
      })
    }), /* @__PURE__ */ jsxs(Content, {
      children: [/* @__PURE__ */ jsx(Title, {
        children: title
      }), inner && /* @__PURE__ */ jsx(SmallText, {
        children: inner
      })]
    })]
  }));
}

export { InfoMessage as default };
