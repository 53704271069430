import styled, { css } from 'styled-components';
import { typography, color, space, layout } from 'styled-system';
import ButtonBase from '@mui/material/ButtonBase';
import { darken } from '@mui/material/styles';
import { responsiveProperty } from '../../styles/utils/responsiveUtils.js';
import { btnSizes } from './Button.sizes.js';
import { btnColors } from './Button.variants.js';

var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
const buttonCss = css`
  appearance: none;
  position: relative;
  padding: 0.5em 1em;
  font-weight: 500;
  line-height: 1.25;
  cursor: ${({ $isLoading }) => $isLoading ? "progress" : "pointer"};
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-decoration: none;
  text-transform: none;
  transition-duration: ${({ theme }) => theme.transitions.duration.shorter}ms;

  box-shadow: none;
  fill: currentColor;
  border: none;
  width: fit-content;

  &:hover {
    text-decoration: none;
    box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%),
      0px 1px 10px 0px rgb(0 0 0 / 12%);
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
    transform: none;
    filter: none;
    pointer-events: none;
  }

  ${({ $size }) => {
  const _a = btnSizes[$size], { maxHeight, minHeight } = _a, otherProps = __objRest(_a, ["maxHeight", "minHeight"]);
  return __spreadValues(__spreadValues({}, responsiveProperty({
    max: maxHeight,
    min: minHeight,
    cssProperty: "height",
    unit: "rem"
  })), otherProps);
}};

  ${({ $color, theme }) => {
  const { color: color2, background, hoverBg } = btnColors(theme)[$color];
  return {
    color: color2,
    background,
    "&:hover": {
      background: hoverBg != null ? hoverBg : darken(background, 0.2)
    }
  };
}};

  .btn-icon-start {
    margin-right: 0.75em;
  }

  .btn-icon-end {
    margin-left: 0.75em;
  }
`;
const ButtonStyled = styled(ButtonBase)`
  ${buttonCss};
  ${typography};
  ${color};
  ${space};
  ${layout};
`;

export { ButtonStyled, buttonCss };
