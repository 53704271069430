import styled from 'styled-components';
import { alpha } from '@mui/material/styles';
import { margin } from 'styled-system';

var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
const Container = styled.div((_a) => {
  var _b = _a, { theme, $color } = _b, rest = __objRest(_b, ["theme", "$color"]);
  return __spreadValues({
    display: "flex",
    alignItems: "flex-start",
    padding: theme.spacing(1.25, 1.5),
    border: `1px solid ${$color}`,
    background: alpha($color, 0.5),
    color: "inherit",
    fontSize: theme.fontSizes.default,
    borderRadius: theme.radii.small
  }, margin(__spreadValues({ theme }, rest)));
});
const Content = styled.div(() => ({
  paddingLeft: 10,
  flex: 1
}));
const Title = styled.div(() => ({
  fontWeight: 500
}));
const SmallText = styled.div(({ theme }) => ({
  fontSize: theme.fontSizes.small,
  margin: "0.5em 0 0",
  opacity: 0.9
}));
const IconWrapper = styled.div(({ $color }) => ({
  color: $color
}));

export { Container, Content, IconWrapper, SmallText, Title };
