import { useOnErrorSnackbar } from '@azondo/utils';
import { useCallback } from 'react';

function useOnApiError(onError) {
  const onErrorSnackbar = useOnErrorSnackbar();
  return useCallback((apiError, ...args) => {
    onError == null ? void 0 : onError(apiError, ...args);
    return onErrorSnackbar(apiError, apiError.statusCode ? `http status: ${apiError.statusCode}` : void 0);
  }, [onError, onErrorSnackbar]);
}

export { useOnApiError };
