const paperNatures = ({ palette }) => ({
  def: {
    background: `linear-gradient(90.11deg, rgba(71, 115, 110, 0.9) 6.64%, rgba(71, 96, 108, 0.96) 95.84%), rgba(255, 255, 255, 0.9);`,
    color: palette.textInverted
  },
  semiTransparent: {
    background: `linear-gradient(90deg, rgba(71, 130, 112, 0.6) 5%, rgba(82, 106, 116, 0.7) 95%), rgba(255, 255, 255, 0.1)`,
    color: palette.textInverted
  },
  lightGreen: {
    background: `linear-gradient(180deg, rgba(219, 255, 229, 0.2) 0%, rgba(74, 255, 124, 0.092) 100%);`,
    color: palette.text.primary
  },
  light: {
    background: `linear-gradient(251.76deg, rgba(155, 212, 171, 0.5) -0.42%, rgba(234, 255, 249, 0.5) 36.94%), linear-gradient(0deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), rgba(255, 255, 255, 0.7)`,
    color: palette.text.primary
  },
  white: {
    background: palette.common.white,
    color: palette.text.primary
  }
});

export { paperNatures };
