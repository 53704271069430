import autoBind from 'auto-bind';

class RoleApi {
  constructor(api) {
    this.api = api;
    autoBind(this);
  }
  me() {
    return this.api.get("/userProfile");
  }
  roles() {
    return this.api.get("/roles");
  }
}

export { RoleApi };
