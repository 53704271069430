import AdapterMoment from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import CircularProgress from '@mui/material/CircularProgress';
import { Suspense } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { ReactQueryProvider } from '@azondo/utils';
import { ReactQueryDevtools } from 'react-query/devtools';
import StylesProvider from '../StylesProvider/StylesProvider.js';
import SnackbarProvider from '../SnackbarProvider/SnackbarProvider.js';
import MuiConfirmProvider from '../MuiConfirmProvider/MuiConfirmProvider.js';
import MfaHandlerProvider from '../../contexts/MfaHandler/MfaHandlerProvider.js';
import '../../contexts/MfaHandler/MfaHandlerContext.js';
import { I18nProvider } from '../../contexts/I18nContext/I18nContext.js';
import 'i18next';
import 'i18next-browser-languagedetector';
import 'i18next-http-backend';
import 'react-i18next';
import { jsx, jsxs } from 'react/jsx-runtime';

const AppProvider = ({
  children,
  platform
}) => {
  return /* @__PURE__ */ jsx(StylesProvider, {
    platform,
    children: /* @__PURE__ */ jsx(Suspense, {
      fallback: /* @__PURE__ */ jsx(CircularProgress, {}),
      children: /* @__PURE__ */ jsx(I18nProvider, {
        children: /* @__PURE__ */ jsx(LocalizationProvider, {
          dateAdapter: AdapterMoment,
          children: /* @__PURE__ */ jsx(BrowserRouter, {
            children: /* @__PURE__ */ jsx(SnackbarProvider, {
              children: /* @__PURE__ */ jsx(MuiConfirmProvider, {
                children: /* @__PURE__ */ jsx(QueryParamProvider, {
                  ReactRouterRoute: Route,
                  children: /* @__PURE__ */ jsx(ReactQueryProvider, {
                    children: /* @__PURE__ */ jsxs(MfaHandlerProvider, {
                      children: [/* @__PURE__ */ jsx(ReactQueryDevtools, {
                        initialIsOpen: false
                      }), children]
                    })
                  })
                })
              })
            })
          })
        })
      })
    })
  });
};

export { AppProvider as default };
