import autoBind from 'auto-bind';
import ApiBase from '../core/ApiBase.js';

var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
class AuthCustomerService {
  constructor(props) {
    this.apiBase = new ApiBase(props);
    autoBind(this);
  }
  async loginUser(_a) {
    var _b = _a, {
      mfa_code
    } = _b, user = __objRest(_b, [
      "mfa_code"
    ]);
    const { data, headers } = await this.apiBase.post("/auth/login", {
      user,
      mfa_code
    }, {
      fullResponse: true
    });
    return {
      user: data,
      token: headers.authorization
    };
  }
  async registerUser(_c) {
    var _d = _c, {
      mfa_code
    } = _d, user = __objRest(_d, [
      "mfa_code"
    ]);
    const { data, headers } = await this.apiBase.post("/auth/signup", {
      user,
      mfa_code
    }, {
      fullResponse: true
    });
    return {
      user: data,
      token: headers.authorization
    };
  }
  async logout() {
    return this.apiBase.delete("/auth/logout");
  }
}

export { AuthCustomerService as default };
