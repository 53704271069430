import { useCallback } from 'react';
import set from 'lodash/set';
import { useQueryClient } from 'react-query';

function useUpdateQuery(cacheKey) {
  const queryClient = useQueryClient();
  return useCallback((data, updatePath) => queryClient.setQueryData(cacheKey, (oldUser) => updatePath ? set(oldUser, updatePath, data) : data), [cacheKey, queryClient]);
}

export { useUpdateQuery };
