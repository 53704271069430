import { capitalize } from '../formatters/baseFormatters.js';

var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
const errorTextToProps = (err) => {
  var _a;
  const helperText = err ? capitalize((_a = err.split("_")) == null ? void 0 : _a.join(" ")) : void 0;
  return __spreadValues({
    error: !!err
  }, helperText && { helperText });
};
const extractInputError = (formError) => {
  var _a, _b;
  return errorTextToProps((_b = (_a = formError == null ? void 0 : formError.message) == null ? void 0 : _a.toString()) != null ? _b : void 0);
};

export { errorTextToProps, extractInputError };
