import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import { ModalStyled, animationDuration, ModalContainer, ModalHead, ModalTitle, CloseButton, CloseIcon, ModalBody } from './Modal.styles.js';
import { jsx, jsxs } from 'react/jsx-runtime';

var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
const Modal = (_a) => {
  var _b = _a, {
    children,
    title,
    open,
    onClose,
    maxWidth,
    className,
    style
  } = _b, rest = __objRest(_b, [
    "children",
    "title",
    "open",
    "onClose",
    "maxWidth",
    "className",
    "style"
  ]);
  return /* @__PURE__ */ jsx(ModalStyled, __spreadProps(__spreadValues({
    "aria-labelledby": "modal-title",
    open,
    onClose,
    BackdropComponent: Backdrop,
    BackdropProps: {
      timeout: animationDuration
    }
  }, rest), {
    children: /* @__PURE__ */ jsx(Fade, {
      timeout: animationDuration,
      in: open,
      children: /* @__PURE__ */ jsxs(ModalContainer, {
        maxWidth,
        className,
        style,
        children: [/* @__PURE__ */ jsxs(ModalHead, {
          children: [title && /* @__PURE__ */ jsx(ModalTitle, {
            id: "modal-title",
            children: title
          }), /* @__PURE__ */ jsx(CloseButton, {
            onClick: (e) => onClose == null ? void 0 : onClose(e, "backdropClick"),
            children: /* @__PURE__ */ jsx(CloseIcon, {})
          })]
        }), /* @__PURE__ */ jsx(ModalBody, {
          children
        })]
      })
    })
  }));
};

export { Modal as default };
