const unit = "px";
const values = {
  mobileS: 320,
  mobileM: 375,
  mobileL: 425,
  tabletS: 600,
  tablet: 768,
  tabletL: 992,
  laptop: 1024,
  laptopS: 1199,
  laptopM: 1280,
  laptopL: 1440,
  desktop: 2560,
  collapseAppBar: 800,
  lg: 1280,
  md: 960,
  sm: 600,
  xl: 1920,
  xs: 0
};
const getBreakpointValue = (key) => {
  var _a;
  return typeof key === "number" ? key : (_a = values[key]) != null ? _a : key;
};
const breakpoints = {
  unit,
  values,
  get down() {
    return (key) => {
      const valueInPx = `${getBreakpointValue(key) - 0.5}${unit}`;
      return `@media (max-width:${valueInPx})`;
    };
  },
  get up() {
    return (key) => {
      const valueInPx = `${getBreakpointValue(key)}${unit}`;
      return `@media (min-width:${valueInPx})`;
    };
  }
};

export { breakpoints as default, getBreakpointValue };
