import { SnackbarProvider as SnackbarProvider$1, useSnackbar } from 'notistack';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import IconButton from '@mui/material/IconButton';
import { jsx } from 'react/jsx-runtime';

const CloseButton = ({
  id
}) => {
  const {
    closeSnackbar
  } = useSnackbar();
  return /* @__PURE__ */ jsx(IconButton, {
    onClick: () => closeSnackbar(id),
    children: /* @__PURE__ */ jsx(CloseRoundedIcon, {})
  });
};
const SnackbarProvider = ({
  children
}) => /* @__PURE__ */ jsx(SnackbarProvider$1, {
  maxSnack: 3,
  preventDuplicate: true,
  dense: true,
  action: (key) => /* @__PURE__ */ jsx(CloseButton, {
    id: key
  }, key),
  children
});

export { SnackbarProvider as default };
