import { lazyLoad } from '@azondo/ui/dist/components/LazyLoad'

// RouteType
export const authRoutes = {
  path: '/auth',
  component: lazyLoad(() => import('views/Auth')),

  views: {
    login: {
      path: '/auth/login',
      exact: true,
      component: lazyLoad(() => import('views/Auth/Login')),
    },
    autoLogin: {
      path: '/auth/autologin',
      exact: true,
      component: lazyLoad(() => import('views/Auth/AutoLogin')),
    },
    changeEmailConfirm: {
      path: '/auth/change-email-confirm',
      exact: true,
      component: lazyLoad(() => import('views/Auth/ChangeEmailConfirm')),
    },
  },
} as const
