import { useQuery } from 'react-query';
import { cacheKeys } from '../../cacheKeys.js';
import { RoleApi } from '../../azondoAPIs/RoleApi.js';
import { useAzondoApi } from '../../providers/AzondoApiProvider/AzondoApiProvider.js';
import { useAuth } from '../../providers/AuthProvider/AuthProvider.js';

function useUserProfile() {
  const { isReady } = useAuth();
  const roleApi = useAzondoApi(RoleApi);
  return useQuery(cacheKeys.myRole, roleApi.me, {
    enabled: isReady
  });
}

export { useUserProfile };
