import { useAuth, useMyProfile } from '@azondo/api'
import { Route } from 'react-router-dom'

import { authRoutes } from 'routes'

import { ProtectedRouteProps } from '../index'

import ErrorView from './ErrorView'
import RedirectWithMsg from './RedirectWithMsg'

const ProfileRequired = ({
  authorized,
  verified,
  ...routeProps
}: ProtectedRouteProps): JSX.Element => {
  const { isReady } = useAuth()
  const myProfile = useMyProfile()

  if (!isReady) {
    return <RedirectWithMsg to={authRoutes.views.login.path} />
  }

  // profile load failure
  if (myProfile.error != null || myProfile.data == null) {
    return <ErrorView title='Profile loading failed. Please, try reloading the page' />
  }

  const { user_status } = myProfile.data

  if (verified && user_status !== 'verified') {
    return <ErrorView text={'To view this page your profile must be verified.'} />
  }

  return <Route {...routeProps} />
}

export default ProfileRequired
