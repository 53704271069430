var PaymentProvider = /* @__PURE__ */ ((PaymentProvider2) => {
  PaymentProvider2[PaymentProvider2["powercash"] = 1] = "powercash";
  PaymentProvider2[PaymentProvider2["unlimit"] = 2] = "unlimit";
  PaymentProvider2[PaymentProvider2["checkout"] = 3] = "checkout";
  PaymentProvider2[PaymentProvider2["emerchantpay"] = 4] = "emerchantpay";
  PaymentProvider2[PaymentProvider2["nuvei"] = 5] = "nuvei";
  PaymentProvider2[PaymentProvider2["nuveiServer"] = 6] = "nuveiServer";
  PaymentProvider2[PaymentProvider2["quaife"] = 7] = "quaife";
  return PaymentProvider2;
})(PaymentProvider || {});
const paymentProviderNaming = {
  [1 /* powercash */]: "Powercash",
  [2 /* unlimit */]: "Unlimit",
  [3 /* checkout */]: "Checkout",
  [4 /* emerchantpay */]: "Emerchantpay",
  [5 /* nuvei */]: "Nuvei",
  [6 /* nuveiServer */]: "NuveiServer",
  [7 /* quaife */]: "Quaife"
};

export { PaymentProvider, paymentProviderNaming };
