import { useAuth } from '../../providers/AuthProvider/AuthProvider.js';
import 'react';
import '@azondo/utils';
import 'auto-bind';
import 'axios';
import 'qs';
import 'react/jsx-runtime';
import { useApiQuery } from './useApiQuery.js';

var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
function useApiReadyQuery(queryKey, queryFn, options) {
  const { isReady } = useAuth();
  return useApiQuery(queryKey, queryFn, __spreadProps(__spreadValues({}, options), {
    enabled: isReady
  }));
}

export { useApiReadyQuery };
