import React, { createContext, useEffect, useMemo } from 'react'
import { useMyProfile, User } from '@azondo/api'
import { useContextWithCheck, WithChild } from '@azondo/utils'

import { AccessPoliciesProps } from '.'

const AccessPoliciesContext = createContext<AccessPoliciesProps | undefined>(undefined)

const getMerchantIds = (data: User | undefined, policies?: string[]): number[] => {
  const ids = data?.access_policies?.reduce((acc, p) => {
    if (!policies) {
      return []
    }
    return policies.includes(p.name) ? [...acc, ...(p.merchant_ids || [])] : acc
  }, [] as number[])
  return Array.from(new Set(ids))
}

export const AccessPoliciesProvider = ({ children }: WithChild): JSX.Element => {
  const [activeMerchantId, setActiveMerchantId] = React.useState<number>()
  const [policies, setPolicies] = React.useState<string[]>([])
  const { data: profile } = useMyProfile()

  useEffect(() => {
    // @ts-ignore
    if (profile?.access_policies?.length > 0) {
      const listMerchant = getMerchantIds(profile, policies)
      // const merchantId = listMerchant.length > 0 ? listMerchant[0] : profile?.id
      const merchantId = listMerchant.length > 0 ? listMerchant[0] : undefined
      setActiveMerchantId(merchantId)
    }
  }, [profile, policies])

  const merchantIds: number[] = useMemo(
    () => getMerchantIds(profile, policies),
    [profile, policies],
  )

  const accessPolicies = profile?.access_policies?.map(p => p.name) || []

  const setPoliciesIds = (policies: string[], changeActiveMerchant: boolean) => {
    // @ts-ignore
    if (profile?.access_policies?.length > 0) {
      const listMerchant = getMerchantIds(profile, policies)
      const merchantId = listMerchant.length > 0 ? listMerchant[0] : undefined
      if (!changeActiveMerchant) {
        setActiveMerchantId(merchantId)
      }
      setPolicies(policies)
    }
  }

  return (
    <AccessPoliciesContext.Provider
      value={{
        merchantIds,
        setPoliciesIds,
        accessPolicies,
        policies,
        activeMerchantId,
        setActiveMerchantId,
      }}
    >
      {children}
    </AccessPoliciesContext.Provider>
  )
}

export const useAccessPoliciesContext = () => useContextWithCheck(AccessPoliciesContext)
