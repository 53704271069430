import { combineReducers } from '@reduxjs/toolkit';
import { paginationReducer } from '../pagination/pagination.reducer.js';
import { sortingReducer } from '../sorting/sorting.reducer.js';
import { filterReducer } from '../filter/filter.reducer.js';

const gridPaginationName = (sliceName) => `${sliceName}/pagination`;
const gridSortingName = (sliceName) => `${sliceName}/sorting`;
const gridFilterName = (sliceName) => `${sliceName}/filter`;
const dataGridReducer = (sliceName) => {
  return combineReducers({
    pagination: paginationReducer(gridPaginationName(sliceName)),
    sorting: sortingReducer(gridSortingName(sliceName)),
    filter: filterReducer(gridFilterName(sliceName), { organizations: [] })
  });
};

export { dataGridReducer, gridFilterName, gridPaginationName, gridSortingName };
