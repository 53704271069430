import { use } from 'i18next';
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

const defaultLanguage = "en";
const languages = ["en", "no"];
const i18nextOptions = (appNS) => ({
  fallbackLng: defaultLanguage,
  supportedLngs: languages,
  keySeparator: ".",
  nsSeparator: ":",
  ns: ["ui", appNS],
  defaultNS: appNS,
  load: "languageOnly",
  nonExplicitSupportedLngs: false,
  returnEmptyString: false,
  returnNull: false,
  backend: {
    loadPath: "/locales/{{lng}}/{{ns}}.json",
    addPath: "/locales/add/{{lng}}/{{ns}}"
  },
  parseMissingKeyHandler: (key) => {
    const splitKey = key.split(".");
    return splitKey[splitKey.length - 1];
  },
  react: {
    useSuspense: true,
    defaultTransParent: "div",
    hashTransKey(defaultValue) {
      console.log("missing key", defaultValue);
      return defaultValue;
    }
  }
});
const i18nInitialize = (appNS) => use(I18nextBrowserLanguageDetector).use(Backend).use(initReactI18next).init(i18nextOptions(appNS));

export { defaultLanguage, i18nInitialize, i18nextOptions, languages };
