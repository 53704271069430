import autoBind from 'auto-bind';
import { paymentHeaders } from '../utils/general.js';
import '@azondo/utils';
import '../domain/currency.js';
import '../domain/user/userKyc.js';

var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
class UserTransactionApi {
  constructor(api) {
    this.api = api;
    autoBind(this);
  }
  async getTransaction(uuid) {
    return this.api.get(`/transaction/${uuid}`);
  }
  async getTransactionsHistory(params) {
    return this.api.get(`/transaction/history`, params);
  }
  async makePurchase(body) {
    return this.api.post(`/transaction/purchase`, body);
  }
  async getPaymentInfo(uuid, data) {
    return this.api.get(`/transaction/${uuid}/payment`, data, {
      headers: paymentHeaders()
    });
  }
  async downloadPdfInvoice(uuid) {
    return this.api.get(`/transaction/${uuid}/pdf_invoice`, {}, { responseType: "blob" });
  }
  async cancelTransaction(uuid) {
    return this.api.delete(`/transaction/${uuid}`);
  }
  async transactionWithdraw(data) {
    return this.api.post(`/transaction/withdraw`, data);
  }
  async transactionWithdrawOtp(data) {
    return this.api.post(`/transaction/withdraw/otp`, data);
  }
  async getCardsList(id) {
    return this.api.get(`/transaction/${id}/cards`);
  }
  async addNewCard(id, data) {
    var _a;
    const newData = __spreadProps(__spreadValues({}, data), {
      transaction_id: id,
      exp_year: ((_a = data == null ? void 0 : data.exp_year) == null ? void 0 : _a.length) === 2 ? `20${data == null ? void 0 : data.exp_year}` : data.exp_year
    });
    return this.api.post(`/transaction/${id}/cards`, newData);
  }
  async removeCard(id, cardId) {
    return this.api.delete(`/transaction/${id}/cards/${cardId}`);
  }
  async payWithCard(id, data) {
    return this.api.post(`/transaction/${id}/pay`, data, { headers: paymentHeaders() });
  }
}

export { UserTransactionApi };
