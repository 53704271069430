import { useState, useCallback } from 'react';
import MfaModal from '../../components/MfaModal/MfaModal.js';
import MfaHandlerContext from './MfaHandlerContext.js';
import { jsxs, jsx } from 'react/jsx-runtime';

const MfaHandlerProvider = ({
  children
}) => {
  const [handleSubmit, setHandleSubmit] = useState(void 0);
  const handleClose = useCallback(() => {
    setHandleSubmit(void 0);
  }, []);
  const handleOpen = useCallback((submitFn) => {
    setHandleSubmit(() => submitFn);
  }, []);
  return /* @__PURE__ */ jsxs(MfaHandlerContext.Provider, {
    value: {
      handleClose,
      handleOpen
    },
    children: [children, /* @__PURE__ */ jsx(MfaModal, {
      open: !!handleSubmit,
      onClose: handleClose,
      onSubmit: handleSubmit
    })]
  });
};

export { MfaHandlerProvider as default };
