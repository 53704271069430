const transactionStatusesArr = [
  "In flow",
  "Failed",
  "Success",
  "Timeout",
  "Canceled",
  "Rejected"
];

export { transactionStatusesArr };
