import { ApiErrorBase } from '../core/ApiErrorBase.js';

class ApiException extends ApiErrorBase {
  constructor({ errors }, { status }) {
    const error = errors[0];
    if (error.status === "0002") {
      error.detail = "The link is invalid or has already expired. Please log in again.";
    }
    if (error) {
      super({
        message: error.detail,
        type: error.status,
        statusCode: status
      });
    } else {
      super({
        message: "Unhandled API error",
        statusCode: status
      });
    }
  }
}

export { ApiException };
