import { useCallback, useEffect, createContext } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useContextWithCheck } from '@azondo/utils';
import { languages, defaultLanguage } from './I18n.config.js';
import { jsx } from 'react/jsx-runtime';

const I18nContext = /* @__PURE__ */ createContext(void 0);
const I18nProvider = ({
  children
}) => {
  const {
    i18n
  } = useTranslation();
  const changeLanguage = useCallback((lang) => {
    moment.locale(lang);
    return i18n.changeLanguage(lang);
  }, [i18n]);
  useEffect(() => {
    moment.locale(i18n.language);
  }, [i18n.language]);
  return /* @__PURE__ */ jsx(I18nContext.Provider, {
    value: {
      i18n,
      language: i18n.language,
      languages,
      defaultLanguage,
      changeLanguage
    },
    children
  });
};
const useI18n = () => useContextWithCheck(I18nContext);

export { I18nProvider, useI18n };
