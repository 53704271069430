import { alpha } from '@mui/material/styles';
import { fontSizes } from './typography.js';
import palette from './palette.js';
import { radii } from './shapings.js';

const components = {
  MuiTooltip: {
    defaultProps: {
      arrow: true,
      placement: "top",
      enterDelay: 150,
      leaveDelay: 100
    },
    styleOverrides: {
      arrow: {
        color: "#1F2128"
      },
      tooltip: {
        backgroundColor: "#1F2128"
      },
      tooltipPlacementTop: {
        marginBottom: "1em"
      }
    }
  },
  MuiBreadcrumbs: {
    defaultProps: { separator: "|" },
    styleOverrides: {
      root: {
        fontSize: fontSizes.extraSmall,
        fontWeight: 500,
        color: "inherit",
        textTransform: "uppercase"
      },
      separator: {
        color: palette.text.secondary
      },
      li: {}
    }
  },
  MuiTextField: {
    defaultProps: { variant: "outlined", size: "small" },
    styleOverrides: {}
  },
  MuiCheckbox: {
    defaultProps: { color: "primary" },
    styleOverrides: {}
  },
  MuiButton: {
    defaultProps: {
      color: "primary",
      variant: "contained"
    },
    styleOverrides: {
      root: {
        fontSize: fontSizes.small,
        borderRadius: radii.small,
        fontWeight: 500,
        textTransform: "none",
        lineHeight: 1.25
      },
      contained: {
        boxShadow: "none"
      },
      sizeMedium: {
        minHeight: 40
      }
    }
  },
  MuiSkeleton: {
    defaultProps: { animation: "wave" },
    styleOverrides: {}
  },
  MuiAlert: {
    styleOverrides: {
      standardError: {
        color: palette.error.dark,
        "& $icon": {
          color: palette.error.dark
        }
      },
      root: {
        lineHeight: 1.2,
        fontSize: fontSizes.default,
        alignItems: "center"
      },
      message: {
        padding: "0.25em 0"
      },
      icon: {
        padding: "0"
      }
    }
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        lineHeight: 1.25,
        fontSize: fontSizes.small,
        minHeight: 36
      }
    }
  },
  MuiListItemIcon: {
    styleOverrides: {
      root: {
        minWidth: "auto",
        marginRight: 10
      }
    }
  },
  MuiSvgIcon: {
    defaultProps: {
      fontSize: "inherit"
    },
    styleOverrides: {
      fontSizeInherit: {
        fontSize: "1.4em"
      },
      fontSizeSmall: {
        fontSize: "1.25rem"
      }
    }
  },
  MuiIconButton: {
    defaultProps: {
      size: "medium",
      color: "inherit"
    },
    styleOverrides: {
      sizeSmall: {
        fontSize: "1.1rem"
      },
      sizeMedium: {
        fontSize: "1.2rem"
      },
      sizeLarge: {
        fontSize: "1.3rem"
      }
    }
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        background: palette.bgLight
      },
      sizeSmall: {
        borderRadius: radii.small
      },
      adornedEnd: {
        paddingRight: 0
      }
    }
  },
  MuiTab: {
    styleOverrides: {
      root: {},
      textColorPrimary: {
        "&.Mui-selected": {
          color: palette.text.primary
        }
      }
    }
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        margin: "0.5rem 0 0",
        lineHeight: 1.2,
        fontSize: fontSizes.extraSmall
      }
    }
  },
  MuiTableCell: {
    styleOverrides: {
      head: {
        fontSize: fontSizes.small,
        color: palette.text.secondary,
        height: 44,
        whiteSpace: "nowrap"
      },
      body: {
        height: 56,
        fontSize: fontSizes.small,
        "@media (max-width: 768px)": {
          fontSize: fontSizes.extraSmall
        }
      },
      root: {
        borderColor: palette.border,
        padding: "0.5em",
        lineHeight: 1.2,
        "&:first-child": {
          paddingLeft: "1rem"
        },
        "&:last-child": {
          paddingRight: "1rem",
          width: "1%"
        }
      }
    }
  },
  MuiDataGrid: {
    styleOverrides: {
      root: {
        border: "none",
        background: palette.background.paper,
        minHeight: 500
      },
      overlay: {
        background: alpha(palette.background.paper, 0.8),
        zIndex: 100
      },
      columnHeaderTitle: {
        fontSize: fontSizes.small,
        color: palette.text.secondary
      },
      cell: {
        padding: "0 5px",
        "&:focus": {
          outline: "none"
        }
      },
      columnHeader: {
        "&:focus": {
          outline: "none"
        }
      }
    }
  }
};

export { components as default };
