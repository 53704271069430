import styled from 'styled-components';
import { typography, space, layout } from 'styled-system';
import { gradientOutline } from '../../styles/utils/mixins.js';
import { paperNatures } from './Paper.config.js';

var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
const PaperStyled = styled.div`
  ${({ $nature, theme }) => __spreadValues({}, paperNatures(theme)[$nature])};

  position: relative;
  overflow: hidden;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.02);

  border-radius: ${({ theme }) => theme.radii.medium}px;
  padding: ${({ $withPd }) => $withPd ? `1rem` : void 0};
  backdrop-filter: ${({ $blur }) => $blur ? `saturate(150%) blur(1rem)` : void 0};

  ${typography};
  ${space};
  ${layout};
`;
const Outline = styled.div`
  ${({ $outlineWidth }) => gradientOutline({ width: $outlineWidth })};
`;

export { Outline, PaperStyled };
