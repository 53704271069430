import { createGlobalStyle } from 'styled-components';

const GlobalCss = createGlobalStyle`
  button {
    font: inherit;
  }

  html {
    height: -webkit-fill-available;
  }

  body {
    min-height: 100vh;
    min-height: -webkit-fill-available;
    display: flex;
    background: ${({ theme }) => theme.palette.background.default};
    color: ${({ theme }) => theme.palette.textInverted};
  }

  #root {
    width: 100%;
    display: flex;
    flex: 1;
  }

  b {
    font-weight: 600;
  }

  a {
    color: inherit;
    text-decoration: none;
  }
`;

export { GlobalCss as default };
