import { QueryClient, QueryClientProvider } from 'react-query';
import { useMemo } from 'react';
import { useOnErrorSnackbar } from '../../hooks/useOnErrorSnackbar.js';
import { jsx } from 'react/jsx-runtime';

const ReactQueryProvider = ({
  children
}) => {
  const onErrorSnackbar = useOnErrorSnackbar();
  const queryClient = useMemo(() => {
    const onError = onErrorSnackbar;
    return new QueryClient({
      defaultOptions: {
        queries: {
          refetchOnWindowFocus: false,
          refetchOnMount: false,
          retry: false,
          onError: (err) => onError(err)
        },
        mutations: {
          onError: (err) => onError(err)
        }
      }
    });
  }, [onErrorSnackbar]);
  return /* @__PURE__ */ jsx(QueryClientProvider, {
    client: queryClient,
    children
  });
};

export { ReactQueryProvider };
