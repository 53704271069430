import { css } from 'styled-components';
import { darken } from '@mui/material/styles';

const gradientOutline = ({ width = "1px" } = {}) => css`
  position: absolute;
  inset: 0;
  z-index: 1;
  border-radius: inherit;
  pointer-events: none;
  padding: ${width};

  background: radial-gradient(ellipse 60% 90% at 55% 55%, #c0f3c8 25%, rgba(103, 194, 229, 0.2));
  background-size: auto;
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
`;
const resetButton = () => css`
  appearance: none;
  outline: none;
  background: none;
  border: none;
  border-radius: 0;
  padding: 0;
  margin: 0;
  font-weight: inherit;
  color: inherit;
  cursor: pointer;
  transition-duration: 150ms;
  width: max-content;
`;
const resetButtonAsLink = ({
  color = "inherit",
  underline = "hover",
  darkenCoef = 0.2
} = {}) => css`
  ${resetButton()};
  color: ${color};
  text-decoration: ${underline === "always" ? "underline" : "none"};

  &:hover {
    color: ${darken(color, darkenCoef)};
    text-decoration: ${underline !== "none" ? "underline" : "none"};
  }
`;

export { gradientOutline, resetButton, resetButtonAsLink };
