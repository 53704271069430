import { useCallback, useEffect } from 'react'
import ReactDOM from 'react-dom'
import { useHistory } from 'react-router-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { Auth0Provider } from '@auth0/auth0-react'
import { AppProvider } from '@azondo/ui'
import { ServiceApiProvider } from '@azondo/api-old'
import { AzondoApiProvider, AuthProvider } from '@azondo/api'
import { i18nInitialize } from '@azondo/ui/dist/contexts/I18nContext'
import { WithChild } from '@azondo/utils'

import FontsCss from 'styles/FontsCss'
import App from 'containers/App'
import { store, persistor } from 'redux/store'

import appConfig from './config/app'
import { authRoutes } from './routes'
import { loggerInfo, init } from './plugins/datadog-browser-log'

i18nInitialize('dashboard').then()

const AppAuthProvider = ({ children }: WithChild) => {
  const history = useHistory()

  const onLogout = useCallback(() => {
    persistor.purge().then(() => {
      history.push(authRoutes.views.login.path)
    })
  }, [history])

  const onRedirectCallback = (appState: any) => {
    history.push(appState?.returnTo || window.location.pathname)
  }

  useEffect(() => {
    init()

    const unlisten = history.listen(() => {
      loggerInfo('Page changed')
    })

    return () => {
      unlisten()
    }
  }, [history])

  return (
    <Auth0Provider
      domain={appConfig.auth0Domain}
      clientId={appConfig.auth0ClientId}
      audience={appConfig.auth0Audience}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
    >
      <AuthProvider store={store} onLogout={onLogout}>
        {children}
      </AuthProvider>
    </Auth0Provider>
  )
}

ReactDOM.render(
  <AppProvider platform='dash'>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <AzondoApiProvider
          baseURL={appConfig.apiBaseUrl}
          stagingURL={appConfig.apiStagingUrl}
          platform='dash'
        >
          <AppAuthProvider>
            <ServiceApiProvider apiProps={{ baseURL: appConfig.apiBaseUrlOld }}>
              <FontsCss />
              <App />
            </ServiceApiProvider>
          </AppAuthProvider>
        </AzondoApiProvider>
      </PersistGate>
    </Provider>
  </AppProvider>,
  document.getElementById('root'),
)
