import { Link } from 'react-router-dom';
import { memo, forwardRef } from 'react';
import { ButtonStyled } from './Button.styles.js';
import ButtonContent from './ButtonContent/ButtonContent.js';
import { jsx } from 'react/jsx-runtime';

var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
const Button = /* @__PURE__ */ forwardRef((_a, ref) => {
  var _b = _a, {
    size = "def",
    color = "primary",
    isLoading = false,
    disabled,
    href,
    children,
    text,
    iconStart,
    iconEnd
  } = _b, rest = __objRest(_b, [
    "size",
    "color",
    "isLoading",
    "disabled",
    "href",
    "children",
    "text",
    "iconStart",
    "iconEnd"
  ]);
  return /* @__PURE__ */ jsx(ButtonStyled, __spreadProps(__spreadValues({
    ref,
    $isLoading: isLoading,
    disabled: isLoading || disabled,
    $size: size,
    $color: color,
    href: href ? href.toString() : void 0,
    to: href,
    LinkComponent: Link
  }, rest), {
    children: /* @__PURE__ */ jsx(ButtonContent, {
      text,
      isLoading,
      iconStart,
      iconEnd,
      children
    })
  }));
});
var Button$1 = /* @__PURE__ */ memo(Button);

export { Button$1 as default };
