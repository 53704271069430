import { cacheKeys } from '../../cacheKeys.js';
import { RoleApi } from '../../azondoAPIs/RoleApi.js';
import '../../domain/user/userKyc.js';
import { UserRole } from '../../domain/user/userRole.js';
import { useAzondoApi } from '../../providers/AzondoApiProvider/AzondoApiProvider.js';
import { useApiReadyQuery } from '../helpers/useApiReadyQuery.js';

var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
function useRoles(options) {
  const roleApi = useAzondoApi(RoleApi);
  return useApiReadyQuery(cacheKeys.roles, () => roleApi.roles().catch(() => [{ id: 1, name: UserRole.user }]), __spreadValues({}, options));
}

export { useRoles };
