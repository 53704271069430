import { useQuery } from 'react-query';
import { cacheKeys } from '../../cacheKeys.js';
import { RoleApi } from '../../azondoAPIs/RoleApi.js';
import '../../domain/user/userKyc.js';
import { UserRole } from '../../domain/user/userRole.js';
import { useAzondoApi } from '../../providers/AzondoApiProvider/AzondoApiProvider.js';
import { useAuth } from '../../providers/AuthProvider/AuthProvider.js';
import { useRoles } from './useRoles.js';

function useMyRole() {
  const { isReady } = useAuth();
  const roleApi = useAzondoApi(RoleApi);
  const { data: rolesList } = useRoles();
  return useQuery(cacheKeys.myRole, () => roleApi.me().catch(() => UserRole.user), {
    select: ({ role }) => {
      var _a;
      return ((_a = rolesList == null ? void 0 : rolesList.find((r) => r.id === role)) == null ? void 0 : _a.name) || UserRole.user;
    },
    enabled: isReady && !!(rolesList == null ? void 0 : rolesList.length)
  });
}

export { useMyRole };
