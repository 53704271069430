import { useTheme } from '@mui/material/styles';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';

const getInfoMessageStyles = (theme) => ({
  info: {
    color: theme.palette.info.main,
    Icon: InfoRoundedIcon,
    IconMin: InfoRoundedIcon
  },
  error: {
    color: theme.palette.error.main,
    Icon: HighlightOffRoundedIcon,
    IconMin: CloseRoundedIcon
  },
  warning: {
    color: theme.palette.warning.main,
    Icon: WarningRoundedIcon,
    IconMin: ErrorOutlineRoundedIcon
  },
  success: {
    color: theme.palette.success.main,
    Icon: CheckCircleRoundedIcon,
    IconMin: DoneRoundedIcon
  }
});
const useInfoMessageStyle = (nature) => {
  const stylesMap = getInfoMessageStyles(useTheme());
  return stylesMap[nature] || stylesMap.info;
};

export { useInfoMessageStyle as default };
