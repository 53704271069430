import { lazyLoad } from '@azondo/ui/dist/components/LazyLoad'

export const appRoutes = {
  main: {
    path: '/',
    exact: true,
  },
  nonProtectedRedirect: {
    path: '/non-protect-redirect',
    exact: true,
  },
  profileSettings: {
    path: '/settings',
    component: lazyLoad(() => import('views/Settings')),
    views: {
      profile: {
        path: '/settings/profile',
        exact: true,
        component: lazyLoad(() => import('views/Settings/ProfileSettings')),
      },
      changeEmail: {
        path: '/settings/change-email',
        exact: true,
        component: lazyLoad(() => import('views/Settings/ChangeEmail')),
      },
    },
  },
} as const
