import autoBind from 'auto-bind';
import { tokenToString } from '../utils/tokenUtils.js';

class AuthApi {
  constructor(api) {
    this.api = api;
    autoBind(this);
  }
  register(body) {
    return this.api.post("/auth/register", body);
  }
  loginRequest(body) {
    return this.api.post("/auth/login", body);
  }
  loginWithOtp(body) {
    return this.api.post("/auth/otp", body);
  }
  loginWithToken(token) {
    return this.api.post("/auth/otp_link", {
      otp: token
    });
  }
  loginBankId(body) {
    return this.api.post("/auth/bank_id", body);
  }
  refresh(refreshToken) {
    return this.api.post("/auth/refresh", void 0, {
      headers: {
        Authorization: tokenToString(refreshToken)
      },
      skipAuthRefresh: true
    });
  }
}

export { AuthApi };
