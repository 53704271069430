import { createTheme } from '@mui/material/styles';
import palette from './palette.js';
import typography, { fontSizes, fontFamilies } from './typography.js';
import breakpoints from './breakpoints.js';
import components from './components.js';
import { transitions } from './animations.js';
import { radii, spacing } from './shapings.js';

const theme = createTheme({
  palette,
  fontSizes,
  fontFamilies,
  typography,
  radii,
  spacing,
  space: spacing,
  shape: {
    borderRadius: radii.default
  },
  transitions,
  breakpoints,
  components
});

export { theme as default };
