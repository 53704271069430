import { joinStrings } from '@azondo/utils';
import { kycBanksProvidersArr, kycSteps } from '../domain/user/userKyc.js';

var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
const isBankIdProvider = (kyc_provider) => kycBanksProvidersArr.includes(kyc_provider);
const getStepIndex = (info) => {
  const { kyc_provider, verification_step } = info;
  if (verification_step == null)
    return -1;
  if (kyc_provider == null)
    return kycSteps.manual.indexOf(verification_step);
  return kycSteps[kyc_provider].indexOf(verification_step);
};
const isStepCompleted = (step, userInfo) => getStepIndex(userInfo) > getStepIndex(__spreadProps(__spreadValues({}, userInfo), { verification_step: step }));
const isVerificationDone = ({
  kyc_provider,
  verification_step
}) => {
  if (isBankIdProvider(kyc_provider))
    return verification_step === "done";
  if (kyc_provider === "manual")
    return verification_step === "done";
  return false;
};
const isProfileVerified = (_a) => {
  var _b = _a, { user_status } = _b, info = __objRest(_b, ["user_status"]);
  if (user_status === "verified")
    return true;
  return isVerificationDone(info);
};
const userFullName = (user) => joinStrings([user == null ? void 0 : user.first_name, user == null ? void 0 : user.last_name]);
const userNameLabel = (user) => {
  if ((user == null ? void 0 : user.first_name) != null)
    return userFullName(user);
  return user == null ? void 0 : user.email;
};

export { getStepIndex, isBankIdProvider, isProfileVerified, isStepCompleted, isVerificationDone, userFullName, userNameLabel };
