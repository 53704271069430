type Env = 'development' | 'production' | 'staging'

const appConfig = {
  apiBaseUrlOld: 'https://api.dev.nordikcoin.com/merchant-hub',
  apiBaseUrl: process.env.REACT_APP_API_URL || 'https://api.dev.nordikcoin.com/merchant-hub/api/v1',
  apiStagingUrl:
    process.env.REACT_APP_API_URL_STAGING ||
    process.env.REACT_APP_API_URL ||
    'https://api.dev.nordikcoin.com/merchant-hub/api/v1',
  appEnv: (process.env.REACT_APP_ENV || process.env.NODE_ENV || 'production') as Env,
  auth0Domain: process.env.REACT_APP_AUTH0_DOMAIN || '',
  auth0ClientId: process.env.REACT_APP_AUTH0_CLIENT_ID || '',
  auth0Audience: process.env.REACT_APP_AUTH0_AUDIENCE || '',
  datadogClientToken: process.env.DATADOG_CLIENT_TOKEN || '',
  datadogEnv: process.env.DATADOG_ENV || '',
}

export default appConfig
