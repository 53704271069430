import { fontSizes } from '../../styles/theme/typography.js';
import { radii } from '../../styles/theme/shapings.js';

const btnSizes = {
  small: {
    minHeight: 1.8,
    maxHeight: 2,
    fontSize: fontSizes.small,
    borderRadius: radii.small
  },
  def: {
    minHeight: 2.25,
    maxHeight: 2.5,
    fontSize: fontSizes.small,
    borderRadius: radii.small
  }
};

export { btnSizes };
