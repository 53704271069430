import autoBind from 'auto-bind';

class UserApi {
  constructor(api) {
    this.api = api;
    autoBind(this);
  }
  me() {
    return this.api.get("/user/me");
  }
  createUser(merchantIds, data) {
    const headers = (merchantIds == null ? void 0 : merchantIds.length) > 0 ? {
      "X-Merchant-Ids": merchantIds.join(",")
    } : void 0;
    return this.api.post(`/createUser`, data, { headers });
  }
  deleteUser(email) {
    return this.api.delete(`/user?email=${encodeURIComponent(email)}`);
  }
  updateUser(data) {
    const merchant_ids = encodeURIComponent(data.merchantIds.join("|"));
    return this.api.put(`/user?email=${encodeURIComponent(data.email)}&role_id=${data.roleId}&merchant_ids=${merchant_ids}`);
  }
  changeUserRole({
    email,
    role_id,
    merchant_id
  }) {
    return this.api.get(`/changeUserRole`, { email, role_id, merchant_id });
  }
  setBasicInfo(body) {
    return this.api.post("/user/basic_info", body);
  }
  getResidenceData() {
    return this.api.get("/user/residence_data");
  }
  getKycDetails() {
    return this.api.get("/user/kyc");
  }
  setResidenceData(address) {
    return this.api.post("/user/residence_data", address);
  }
  setAdditionalData(data) {
    return this.api.post("/user/additional_data", data);
  }
  setAdditionalDataManual(data) {
    return this.api.post("/user/personal_info", data);
  }
  changeEmailInit() {
    return this.api.post("/user/change_email");
  }
  changeEmailOtpOld(body) {
    return this.api.post("/user/change_email_otp_old", body);
  }
  changeEmailOtpNew(body) {
    return this.api.post("/user/change_email_otp_new", body);
  }
  changeEmailConfirm(body) {
    return this.api.post("/user/change_email_token_new", body);
  }
}

export { UserApi };
