import { useQueryClient, useMutation } from 'react-query';
import { useFrozenRate } from '../queries/useFrozenRate.js';

var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
function useMutationWithRate(params, _a) {
  var _b = _a, { mutationFn, onError, retry } = _b, options = __objRest(_b, ["mutationFn", "onError", "retry"]);
  var _a2;
  const queryClient = useQueryClient();
  const rateQuery = useFrozenRate(params);
  const rateId = (_a2 = rateQuery.data) == null ? void 0 : _a2.id;
  const mutation = useMutation(__spreadValues({
    mutationFn: mutationFn ? (variables) => mutationFn(rateId, variables) : void 0,
    retry: (failureCount, err) => {
      var _a3;
      if (err.statusCode === 401) {
        rateQuery.refetch();
        return false;
      }
      const retryProp = (_a3 = queryClient.defaultQueryOptions().retry) != null ? _a3 : retry;
      if (retryProp && typeof retryProp === "function") {
        return retryProp(failureCount, err);
      }
      return false;
    },
    onError: onError ? (error, variables, context) => {
      if (error.statusCode && error.statusCode === 401) {
        mutation.mutate(variables);
      }
      return onError(error, variables, context);
    } : void 0
  }, options));
  return mutation;
}

export { useMutationWithRate };
