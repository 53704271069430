import autoBind from 'auto-bind';
import ApiBase from '../core/ApiBase.js';

var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
class AuthAdminService {
  constructor(props) {
    this.apiBase = new ApiBase(props);
    autoBind(this);
  }
  async loginUser(_a) {
    var _b = _a, {
      mfa_code
    } = _b, user = __objRest(_b, [
      "mfa_code"
    ]);
    const { data, headers } = await this.apiBase.post("/auth/login", {
      user,
      mfa_code
    }, {
      fullResponse: true
    });
    return {
      user: data,
      token: headers.authorization
    };
  }
  async registerUser(_c) {
    var _d = _c, {
      mfa_code
    } = _d, user = __objRest(_d, [
      "mfa_code"
    ]);
    const { data, headers } = await this.apiBase.post("/auth/signup", {
      user,
      mfa_code
    }, {
      fullResponse: true
    });
    return {
      user: data,
      token: headers.authorization
    };
  }
  async logout() {
    return this.apiBase.delete("/auth/logout");
  }
  async emailConfirm(confirmation_token) {
    const { data, headers } = await this.apiBase.get("/auth/confirmation", {
      confirmation_token
    }, {
      fullResponse: true
    });
    return {
      user: data.user,
      token: headers.authorization
    };
  }
  async resendEmailConfirm(email) {
    return this.apiBase.post("/auth/confirmation", {
      user: {
        email
      }
    });
  }
  async passwordResetRequest({ email }) {
    return this.apiBase.post("/auth/password", {
      user: {
        email
      }
    });
  }
  async passwordResetConfirm({
    reset_password_token,
    password,
    password_confirmation
  }) {
    return this.apiBase.patch("/auth/password", {
      user: {
        reset_password_token,
        password,
        password_confirmation
      }
    });
  }
  async changePassword({
    current_password,
    password,
    password_confirmation
  }) {
    return this.apiBase.put("/auth/signup", {
      user: {
        current_password,
        password,
        password_confirmation
      }
    });
  }
  async getNewSecretMFA() {
    return this.apiBase.get("/auth/google_mfa/new");
  }
  async proceedMFA({ activation, mfa_code }) {
    return this.apiBase.post("/auth/google_mfa", {
      user_mfa_session: { activation, mfa_code }
    });
  }
}

export { AuthAdminService as default };
