import { ApiErrorBase } from '../core/ApiErrorBase.js';

class ValidationException extends ApiErrorBase {
  constructor({ detail }, { status }) {
    const error = Array.isArray(detail) ? detail[0] : detail;
    if (typeof error === "string") {
      super({
        message: error,
        statusCode: status
      });
    } else if (typeof error === "object") {
      super({
        message: error.msg,
        type: error.type,
        statusCode: status
      });
    } else {
      super({
        message: "Unhandled API error",
        statusCode: status
      });
    }
  }
}

export { ValidationException };
