import { useMemo } from 'react';
import { gridPaginationName, gridSortingName, gridFilterName } from '../../redux/dataGrid/dataGrid.reducer.js';
import { useReduxPagination } from './useReduxPagination.js';
import { useReduxSorting } from './useReduxSorting.js';
import { useReduxFilter } from './useReduxFilter.js';

var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
const useReduxDataGrid = (sliceName) => {
  const { paginationApiParams, gridPaginationProps } = useReduxPagination(gridPaginationName(sliceName));
  const { sortingApiParams, gridSortingProps } = useReduxSorting(gridSortingName(sliceName));
  const {
    filterApiParams,
    filterItems,
    appliedFilterItems,
    updateFilter,
    deleteFilter,
    addNewFilter,
    saveFilter,
    openFilter,
    toggleOrganization,
    organizations
  } = useReduxFilter(gridFilterName(sliceName));
  const tableProps = useMemo(() => {
    const onSortModelChange = (model, details) => {
      gridSortingProps.onSortModelChange(model, details);
      gridPaginationProps.onPageChange(0, details);
    };
    return __spreadProps(__spreadValues(__spreadValues({}, gridPaginationProps), gridSortingProps), {
      onSortModelChange,
      filterProps: {
        filterItems,
        appliedFilterItems,
        updateFilter,
        deleteFilter,
        addNewFilter,
        saveFilter,
        openFilter,
        toggleOrganization,
        organizations
      }
    });
  }, [
    filterItems,
    appliedFilterItems,
    gridPaginationProps,
    gridSortingProps,
    addNewFilter,
    deleteFilter,
    updateFilter,
    saveFilter,
    openFilter,
    organizations,
    toggleOrganization
  ]);
  const apiParams = useMemo(() => __spreadValues(__spreadValues(__spreadValues({}, paginationApiParams), sortingApiParams), filterApiParams), [paginationApiParams, sortingApiParams, filterApiParams]);
  return {
    tableProps,
    apiParams
  };
};

export { useReduxDataGrid };
