import autoBind from 'auto-bind';

class MerchantApi {
  constructor(api) {
    this.api = api;
    autoBind(this);
  }
  getTransactionsB2B(params) {
    return this.api.get("/m/transactions/", params);
  }
  importTransactionsB2B(params) {
    return this.api.get("/m/transactions/csv", params, {
      responseType: "blob",
      timeout: 120 * 1e3
    });
  }
  getTransactionB2B(uuid) {
    return this.api.get(`/m/transactions/${uuid}`);
  }
  dashboardStats(params) {
    return this.api.get("/m/stats/", params);
  }
  dashboardChartExecutedAmount(params) {
    return this.api.get("/m/stats/charts/executed_amount", params, {
      timeout: 20 * 1e3
    });
  }
  getPaymentsB2B(uuid) {
    return this.api.get(`/m/transactions/${uuid}/payments`);
  }
}

export { MerchantApi };
