import styled from 'styled-components';

const Wrapper = styled.div`
  opacity: ${({ $show }) => $show ? 1 : 0};
  transition-duration: inherit;
  display: inherit;
  justify-content: inherit;
  align-items: inherit;
  flex-direction: inherit;
`;

export { Wrapper };
