import { lazyLoad } from '@azondo/ui/dist/components/LazyLoad'

export const merchantRoutes = {
  dashboard: {
    path: '/',
    exact: true,
    component: lazyLoad(() => import('views/DashboardMerchant')),
    policies: ['M', 'MD'],
    inProgress: true,
  },
  b2bTransactions: {
    path: '/transactions',
    exact: true,
    component: lazyLoad(() => import('views/TransactionsB2B')),
    policies: ['M', 'MT', 'SA', 'A'],
    inProgress: true,
  },
  transactionInfo: {
    path: '/transactions/:uuid',
    component: lazyLoad(() => import('views/TransactionInfoB2B/TransactionInfoB2B')),
    policies: ['M', 'MT', 'SA', 'A'],
    inProgress: true,
    views: {
      generalInfo: {
        path: '/transactions/:uuid',
        exact: true,
        component: lazyLoad(() => import('views/TransactionInfoB2B/TransactionB2B')),
        policies: ['M', 'MT', 'SA', 'A'],
        inProgress: true,
      },
      payments: {
        path: '/transactions/:uuid/payments',
        exact: true,
        component: lazyLoad(() => import('views/TransactionInfoB2B/TransactionPaymentsB2B')),
        policies: ['M', 'MT', 'SA', 'A'],
        inProgress: true,
      },
    },
  },
  webhooks: {
    path: '/webhooks',
    exact: true,
    component: lazyLoad(() => import('views/Webhooks')),
    inProgress: true,
  },
  apiKeys: {
    path: '/api-keys',
    exact: true,
    component: lazyLoad(() => import('views/ApiKeys')),
    policies: ['SA', 'A'],
    inProgress: true,
  },
} as const
