import { useMyProfile } from '@azondo/api'

export const useCheckAccess = (policies: any) => {
  const myProfile = useMyProfile()
  let access = false
  if (!policies) {
    access = true
  }

  const accessPolicies = myProfile?.data?.access_policies?.map(p => p.name) || []
  if (accessPolicies && policies) {
    access = accessPolicies.filter(x => (policies || []).includes(x)).length > 0
  }

  return { isBlocked: !access }
}
