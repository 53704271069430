var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
const currenciesArr = ["btc", "eur", "nok", "sek", "dkk", "usd", "cad"];
const fiatDefault = {
  decimals: 2,
  maxValue: 999999
};
const currenciesInfo = {
  btc: {
    name: "BTC",
    apiName: "btc",
    fullName: "Bitcoin",
    decimals: 8,
    maxValue: 50
  },
  eur: __spreadValues({
    name: "EUR",
    apiName: "eur",
    fullName: "Euro"
  }, fiatDefault),
  nok: __spreadValues({
    name: "NOK",
    apiName: "nok",
    fullName: "Norwegian Krone"
  }, fiatDefault),
  sek: __spreadValues({
    name: "SEK",
    apiName: "sek",
    fullName: "Sweden Krone"
  }, fiatDefault),
  dkk: __spreadValues({
    name: "DKK",
    apiName: "dkk",
    fullName: "Denmark Krone"
  }, fiatDefault),
  usd: __spreadValues({
    name: "USD",
    apiName: "usd",
    fullName: "USA Dollar"
  }, fiatDefault),
  cad: __spreadValues({
    name: "CAD",
    apiName: "cad",
    fullName: "Canadian Dollar"
  }, fiatDefault)
};

export { currenciesArr, currenciesInfo };
