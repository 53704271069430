import { alpha } from '@mui/material/styles';

const btnColors = ({ palette }) => ({
  primary: {
    background: palette.primary.main,
    color: palette.primary.contrastText,
    hoverBg: palette.primary.dark
  },
  secondary: {
    background: alpha(palette.secondary.main, 0.9),
    color: palette.secondary.contrastText,
    hoverBg: palette.secondary.dark
  },
  success: {
    background: palette.success.main,
    color: palette.success.contrastText,
    hoverBg: palette.success.dark
  },
  warning: {
    background: palette.warning.main,
    color: palette.warning.contrastText,
    hoverBg: palette.warning.dark
  },
  error: {
    background: palette.error.main,
    color: palette.error.contrastText,
    hoverBg: palette.error.dark
  }
});

export { btnColors };
