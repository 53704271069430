import { createGlobalStyle } from 'styled-components'
import { fontFace } from '@azondo/ui/dist/styles'
import FontLight from '@azondo/ui/public/fonts/Inter/Inter-Light.ttf'
import FontRegular from '@azondo/ui/public/fonts/Inter/Inter-Regular.ttf'
import FontMedium from '@azondo/ui/public/fonts/Inter/Inter-Medium.ttf'
import FontSemiBold from '@azondo/ui/public/fonts/Inter/Inter-SemiBold.ttf'
import FontBold from '@azondo/ui/public/fonts/Inter/Inter-Bold.ttf'

const FontsCss = createGlobalStyle`
  ${fontFace('Inter', FontLight, 300)};
  ${fontFace('Inter', FontRegular, 400)};
  ${fontFace('Inter', FontMedium, 500)};
  ${fontFace('Inter', FontSemiBold, 600)};
  ${fontFace('Inter', FontBold, 700)};
`

export default FontsCss
