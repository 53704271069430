import styled from 'styled-components';
import { grid, margin } from 'styled-system';
import Button from '../Button/Button.js';

const FormStyled = styled.form.attrs({ noValidate: true })`
  width: 100%;
  display: grid;
  ${grid};
  ${margin};
`;
const SubmitButton = styled(Button).attrs({ type: "submit" })`
  width: 100%;
`;

export { FormStyled, SubmitButton };
