import autoBind from 'auto-bind';

class NotificationApi {
  constructor(api) {
    this.api = api;
    autoBind(this);
  }
  getNotifications(transactionId, params) {
    return this.api.get(`/${transactionId}/notifications`, params);
  }
  getNotificationById(transactionId, notificationId) {
    return this.api.get(`/${transactionId}/${notificationId}/notification`);
  }
}

export { NotificationApi };
