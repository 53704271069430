import { memo } from 'react'
import Paper from '@azondo/ui/dist/components/Paper'

import { InProgressProps } from '.'

function InProgress({ ...rest }: InProgressProps): JSX.Element {
  return (
    <Paper
      withPd
      blur
      outline
      outlineWidth='3px'
      nature='light'
      style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      {...rest}
    >
      <p style={{ fontWeight: 600, fontSize: '1.25rem' }}>In Progress</p>
    </Paper>
  )
}

export default memo(InProgress)
