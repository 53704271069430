import { ConfirmProvider } from 'material-ui-confirm';
import useTranslation from '../../hooks/useTranslation.js';
import { jsx } from 'react/jsx-runtime';

const MuiConfirmProvider = ({
  children
}) => {
  const {
    t
  } = useTranslation();
  return /* @__PURE__ */ jsx(ConfirmProvider, {
    defaultOptions: {
      title: t("confirmDialog.title"),
      confirmationText: t("confirmDialog.confirmationText"),
      cancellationText: t("confirmDialog.cancellationText"),
      confirmationButtonProps: {
        autoFocus: true
      },
      dialogProps: {
        fullWidth: false,
        maxWidth: "md"
      }
    },
    children
  });
};

export { MuiConfirmProvider as default };
