import { lazyLoad } from '@azondo/ui/dist/components/LazyLoad'

// RouteType
export const adminRoutes = {
  dashboard: {
    path: '/',
    exact: true,
    component: lazyLoad(() => import('views/DashboardAdmin')),
    policies: ['SA', 'AMD'],
    verified: true,
  },
  b2cTransactions: {
    path: '/b2c-transactions',
    exact: true,
    component: lazyLoad(() => import('views/TransactionsB2C')),
    policies: ['SA', 'AB2C'],
    inProgress: true,
    verified: true,
  },
  b2bTransactions: {
    path: '/b2b-transactions',
    exact: true,
    component: lazyLoad(() => import('views/TransactionsB2B')),
    policies: ['SA', 'AB2B'],
    verified: true,
  },
  admins: {
    path: '/admins',
    exact: true,
    component: lazyLoad(() => import('views/Admins')),
    policies: ['SA'],
    inProgress: true,
    verified: true,
  },
  merchants: {
    path: '/merchants',
    exact: true,
    component: lazyLoad(() => import('views/Merchants')),
    policies: ['SA', 'AU'],
    inProgress: true,
    verified: true,
  },
  blockCustomer: {
    path: '/block-customers',
    exact: true,
    component: lazyLoad(() => import('views/BlockCustomers')),
  },
  invites: {
    path: '/users',
    exact: true,
    component: lazyLoad(() => import('views/Invites')),
    policies: ['SA', 'AU'],
  },
  userEdit: {
    path: '/users/user-edit',
    exact: true,
    component: lazyLoad(() => import('views/UserEdit')),
  },
  usersInvite: {
    path: '/users/invite',
    exact: true,
    component: lazyLoad(() => import('views/InviteUser')),
    policies: ['SA', 'AU'],
    // inProgress: true,
  },
  users: {
    path: '/users',
    exact: true,
    component: lazyLoad(() => import('views/Invites')),
    policies: ['SA', 'AU'],
    // inProgress: true,
  },
  b2cTransactionInfo: {
    path: '/b2c-transactions/:uuid',
    component: lazyLoad(() => import('views/TransactionInfoB2C/TransactionInfoB2C')),
    policies: ['SA', 'AB2C'],
    inProgress: true,
    verified: true,
    views: {
      generalInfo: {
        path: '/b2c-transactions/:uuid',
        exact: true,
        component: lazyLoad(() => import('views/TransactionInfoB2C/TransactionB2C')),
        policies: ['SA', 'AB2C'],
      },
      payments: {
        path: '/b2c-transactions/:uuid/payments',
        exact: true,
        component: lazyLoad(() => import('views/TransactionInfoB2C/TransactionPaymentsB2C')),
        policies: ['SA', 'AB2C'],
      },
    },
  },
  b2bTransactionInfo: {
    path: '/b2b-transactions/:uuid',
    component: lazyLoad(() => import('views/TransactionInfoB2B')),
    policies: ['SA', 'AB2B'],
    verified: true,
    views: {
      generalInfo: {
        path: '/b2b-transactions/:uuid',
        exact: true,
        component: lazyLoad(() => import('views/TransactionInfoB2B/TransactionB2B')),
        policies: ['SA', 'AB2B'],
      },
      notifications: {
        path: '/b2b-transactions/:uuid/notifications',
        exact: true,
        component: lazyLoad(() => import('views/TransactionInfoB2B/TransactionNotificationsB2B')),
        policies: ['SA'],
      },
      payments: {
        path: '/b2b-transactions/:uuid/payments',
        exact: true,
        component: lazyLoad(() => import('views/TransactionInfoB2B/TransactionPaymentsB2B')),
        policies: ['SA', 'AB2B'],
      },
    },
  },
  integrations: {
    path: '/integrations',
    exact: true,
    component: lazyLoad(() => import('views/Integrations')),
    policies: ['SA'],
    verified: true,
  },
  paymentsAdmin: {
    path: '/payments-admin-panel',
    exact: true,
    component: lazyLoad(() => import('views/PaymentsAdmin')),
    policies: ['SA'],
    verified: true,
  },
  userInfo: {
    path: '/users/:user_id',
    component: lazyLoad(() => import('views/UserInfo')),
    policies: ['SA', 'AU'],
    inProgress: true,
    verified: true,
    views: {
      generalInfo: {
        path: '/users/:user_id',
        exact: true,
        component: lazyLoad(() => import('views/UserInfo/UserGeneralInfo')),
        policies: ['SA', 'AU'],
        inProgress: true,
      },
      dashboard: {
        path: '/users/:user_id/dashboard',
        exact: true,
        component: lazyLoad(() => import('views/UserInfo/MerchantDashboard')),
        policies: ['SA'],
        inProgress: true,
      },
      webhooks: {
        path: '/users/:user_id/webhooks',
        exact: true,
        component: lazyLoad(() => import('components/InProgress')),
        policies: ['SA', 'AU'],
        inProgress: true,
      },
      b2bTransactions: {
        path: '/users/:user_id/b2b-transactions',
        exact: true,
        component: lazyLoad(() => import('views/UserInfo/UserB2BTransactions')),
        policies: ['SA', 'AU'],
        inProgress: true,
      },
      b2cTransactions: {
        path: '/users/:user_id/b2c-transactions',
        exact: true,
        component: lazyLoad(() => import('views/UserInfo/UserB2CTransactions')),
        policies: ['SA', 'AU'],
        inProgress: true,
      },
      apiKeys: {
        path: '/users/:user_id/api-keys',
        exact: true,
        component: lazyLoad(() => import('views/UserInfo/ApiKeys')),
        policies: ['SA', 'AU'],
        inProgress: true,
      },
    },
  },
} as const
