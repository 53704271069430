import { memo, isValidElement, cloneElement } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Fade from '@mui/material/Fade';
import { AbsoluteCenter } from '../../Shared/Shared.js';
import { Wrapper } from './ButtonContent.styles.js';
import { jsxs, Fragment, jsx } from 'react/jsx-runtime';

var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
function ButtonContent({
  isLoading,
  children,
  text,
  iconStart,
  iconEnd
}) {
  return /* @__PURE__ */ jsxs(Fragment, {
    children: [/* @__PURE__ */ jsx(Fade, {
      in: isLoading,
      unmountOnExit: true,
      children: /* @__PURE__ */ jsx(AbsoluteCenter, {
        children: /* @__PURE__ */ jsx(CircularProgress, {
          size: "1.5em",
          color: "inherit",
          thickness: 4,
          style: {
            display: "block"
          }
        })
      })
    }), /* @__PURE__ */ jsxs(Wrapper, {
      $show: !isLoading,
      children: [/* @__PURE__ */ isValidElement(iconStart) && /* @__PURE__ */ cloneElement(iconStart, __spreadProps(__spreadValues({}, iconStart.props), {
        className: "btn-icon-start"
      })), text != null ? text : children, /* @__PURE__ */ isValidElement(iconEnd) && /* @__PURE__ */ cloneElement(iconEnd, __spreadProps(__spreadValues({}, iconEnd.props), {
        className: "btn-icon-end"
      }))]
    })]
  });
}
var ButtonContent$1 = /* @__PURE__ */ memo(ButtonContent);

export { ButtonContent$1 as default };
