import { useMemo, createContext } from 'react';
import { useContextWithCheck } from '@azondo/utils';
import { AzondoApiBase } from '../../core/AzondoApiBase.js';
import { jsx } from 'react/jsx-runtime';

const AzondoApiContext = /* @__PURE__ */ createContext(void 0);
const AzondoApiProvider = ({
  children,
  baseURL,
  stagingURL,
  platform
}) => {
  const apiInstance = useMemo(() => new AzondoApiBase({
    baseURL,
    platform
  }), [baseURL, platform]);
  const apiStagingInstance = useMemo(() => new AzondoApiBase({
    baseURL: stagingURL,
    platform
  }), [stagingURL, platform]);
  return /* @__PURE__ */ jsx(AzondoApiContext.Provider, {
    value: {
      apiInstance,
      apiStagingInstance
    },
    children
  });
};
const useAzondoApiContext = () => useContextWithCheck(AzondoApiContext);
function useAzondoApi(ApiService) {
  const {
    apiInstance
  } = useContextWithCheck(AzondoApiContext);
  return useMemo(() => new ApiService(apiInstance), [ApiService, apiInstance]);
}
function useAzondoStagingApi(ApiService) {
  const {
    apiStagingInstance
  } = useContextWithCheck(AzondoApiContext);
  return useMemo(() => new ApiService(apiStagingInstance), [ApiService, apiStagingInstance]);
}

export { AzondoApiProvider, useAzondoApi, useAzondoApiContext, useAzondoStagingApi };
