import { useEffect } from 'react';
import { useYupForm } from '@azondo/utils';
import { useApiMutation } from '@azondo/api';
import { SubmitButton } from '../Form/Form.styles.js';
import Form from '../Form/Form.js';
import TextField from '../TextField/TextField.js';
import Modal from '../Modal/Modal.js';
import { Text } from './MfaModal.styles.js';
import { mfaSchema, codeLength } from './MfaModal.config.js';
import { jsx, jsxs } from 'react/jsx-runtime';

var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
const MfaModal = ({
  open,
  onClose,
  onSubmit
}) => {
  const _a = useYupForm(mfaSchema()), {
    handleSubmit,
    register
  } = _a, formMethods = __objRest(_a, [
    "handleSubmit",
    "register"
  ]);
  const {
    mutate,
    isLoading
  } = useApiMutation(({
    mfa_code
  }) => onSubmit == null ? void 0 : onSubmit(mfa_code), {
    onSuccess: () => {
      onClose();
    }
  });
  const mfaCode = formMethods.watch("mfa_code");
  useEffect(() => {
    if (open && (mfaCode == null ? void 0 : mfaCode.length) === codeLength) {
      mutate({
        mfa_code: mfaCode
      });
    }
  }, [open, mfaCode, mutate]);
  return /* @__PURE__ */ jsx(Modal, {
    open,
    onClose,
    title: "Two-factor authentication",
    maxWidth: 400,
    children: /* @__PURE__ */ jsxs(Form, {
      onSubmit: handleSubmit((data) => mutate(data)),
      children: [/* @__PURE__ */ jsx(Text, {
        children: "Enter your two-factor authentication code from your 2FA app"
      }), /* @__PURE__ */ jsx(TextField, __spreadValues({
        type: "number",
        placeholder: "XXX XXX"
      }, register("mfa_code"))), /* @__PURE__ */ jsx(SubmitButton, {
        isLoading,
        children: "Submit"
      })]
    })
  });
};

export { MfaModal as default };
