import { useEffect } from 'react';
import { useApiMutation } from '../helpers/useApiMutation.js';
import { useAuth } from '../../providers/AuthProvider/AuthProvider.js';
import { useAzondoApi } from '../../providers/AzondoApiProvider/AzondoApiProvider.js';
import 'auto-bind';
import { AuthApi } from '../../azondoAPIs/AuthApi.js';
import '@azondo/utils';
import '../../domain/currency.js';
import '../../domain/user/userKyc.js';

var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
function useAutologin(_a) {
  var _b = _a, {
    token,
    onSuccess,
    onError
  } = _b, options = __objRest(_b, [
    "token",
    "onSuccess",
    "onError"
  ]);
  const { login } = useAuth();
  const authApi = useAzondoApi(AuthApi);
  const _a2 = useApiMutation(() => {
    if (!token)
      throw new Error("Token is not provided");
    return authApi.loginWithToken(token);
  }, __spreadProps(__spreadValues({}, options), {
    onSuccess: (pair, ...args) => {
      login(pair);
      onSuccess == null ? void 0 : onSuccess(pair, ...args);
    },
    onError: (...args) => onError == null ? void 0 : onError(...args)
  })), { mutate } = _a2, rest = __objRest(_a2, ["mutate"]);
  useEffect(() => {
    mutate();
  }, []);
  return __spreadValues({
    mutate
  }, rest);
}

export { useAutologin };
