import { datadogLogs } from '@datadog/browser-logs'

import appConfig from '../config/app'

let initExecuted = false

export function init(): void {
  if (initExecuted) return

  datadogLogs.init({
    //@ts-ignore
    clientToken: appConfig.datadogClientToken,
    site: 'datadoghq.eu',
    env: appConfig.datadogEnv,
    version: appConfig.datadogEnv,
    forwardErrorsToLogs: true,
    forwardConsoleLogs: ['error'],
    sessionSampleRate: 100,
  })

  window.addEventListener('error', event => {
    datadogLogs.logger.error('Global error caught', {
      message: event.message,
      stack: event.error?.stack,
    })
  })

  window.addEventListener('unhandledrejection', event => {
    datadogLogs.logger.error('Unhandled promise rejection', { reason: event.reason })
  })

  initExecuted = true
}

export function loggerInfo(message: string, messageContext?: object): void {
  if (!initExecuted) {
    init()
  }

  datadogLogs.logger.info(message, messageContext)
}

export function loggerWarn(message: string, messageContext?: object): void {
  if (!initExecuted) {
    init()
  }

  datadogLogs.logger.warn(message, messageContext)
}

export function loggerError(message: string, messageContext?: object, error?: Error): void {
  if (!initExecuted) {
    init()
  }

  datadogLogs.logger.error(message, messageContext, error)
}
