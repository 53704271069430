import { createReducers, dataGridReducer } from '@azondo/utils'

export const B2B_TRANSACTIONS_TABLE_NAME = 'b2bTransactionsTable'
export const B2B_NOTIFICATIONS_TABLE_NAME = 'b2bNotificationsTable'
export const B2C_TRANSACTIONS_TABLE_NAME = 'b2cTransactionsTable'
export const USERS_TABLE_NAME = 'usersTable'
export const INVITES_TABLE_NAME = 'invitesTable'
export const WEBHOOKS_TABLE_NAME = 'webhooksTable'
export const FEES_TABLE_NAME = 'feesTable'
export const API_SECRETS_TABLE_NAME = 'apiSecretsTable'

export const dataGridReducers = createReducers(dataGridReducer, [
  B2B_TRANSACTIONS_TABLE_NAME,
  B2C_TRANSACTIONS_TABLE_NAME,
  B2B_NOTIFICATIONS_TABLE_NAME,
  USERS_TABLE_NAME,
  INVITES_TABLE_NAME,
  WEBHOOKS_TABLE_NAME,
  FEES_TABLE_NAME,
  API_SECRETS_TABLE_NAME,
])
